import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import Joyride, { CallBackProps, Step, EVENTS, ACTIONS } from 'react-joyride'
import { useMediaQuery, Theme, useTheme } from '@mui/material'

import { SupportedLanguage } from 'config/i18n-types'
import { InTextExternalLink } from 'Shared/components'
import { ExternalLinks, GoogleTagManagerEvent } from 'Shared/constants'
import { SettingsContext } from 'App/Settings'
import { StepName } from 'App/UserGuide/types'
import { UserGuideTooltip } from './UserGuideTooltip'

const buildStep = (key: string, params?: any) => ({
  title: `app.intro.${key}-title`,
  content: `app.intro.${key}-content`,
  target: `[data-userguide-id="${key}"]`,
  ...params,
})

const UserGuideBase: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const { zIndex } = useTheme()
  const { manageSettings, userGuide } = useContext(SettingsContext)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tabletLandscape'))

  React.useEffect(() => {
    history.listen(() => {
      if (history.action === 'POP') {
        manageSettings.userGuide.completeUserGuide()
      }
    })
  }, [ history ])

  const stepDefinitions: Step[] = React.useMemo(() => {
    const steps = []

    steps[StepName.Welcome] = buildStep('stepWelcome', { target: 'body', placement: 'center' })
    steps[StepName.Holdings] = buildStep('stepHoldings')
    steps[StepName.Summary] = buildStep('stepSummary', { placement: 'right' })
    steps[StepName.HiddenMode] = buildStep('stepHiddenMode')
    steps[StepName.Transactions] = buildStep('stepTransactions', { ...(isMobile && { target: 'body', placement: 'center' }) })
    steps[StepName.Analytics] = buildStep('stepAnalytics', { ...(isMobile && { target: 'body', placement: 'center' }) })
    steps[StepName.Prediction] = buildStep('stepPrediction', { ...(isMobile && { target: 'body', placement: 'center' }) })
    steps[StepName.Final] = buildStep('stepFinal', { target: 'body', placement: 'center' })

    return steps.map(step => ({
      ...step,
      title: t(step.title),
      content: (
        <Trans
          i18nKey={step.content}
          components={{
            termsOfUse: <InTextExternalLink href={ExternalLinks.LEGAL.TERMS_OF_USE(i18n.resolvedLanguage ?? SupportedLanguage.EN)} />,
            privacyPolicy: <InTextExternalLink href={ExternalLinks.LEGAL.PRIVACY_POLICY(i18n.resolvedLanguage ?? SupportedLanguage.EN)} />,
            publicOffer: <InTextExternalLink href={ExternalLinks.LEGAL.PUBLIC_OFFER_AGREEMENT(i18n.resolvedLanguage ?? SupportedLanguage.EN)} />,
          }}
        />
      ),
    }))
  }, [ t ])

  const userGuideLocale = React.useMemo(() => ({
    back: t('common.actions.goBack'),
    close: t('common.actions.close'),
    last: t('app.intro.callToAction'),
    next: t('common.actions.next'),
    open: t('common.actions.start'),
    skip: t('common.actions.skip')
  }), [ t ])

  const handleCallback = (props: CallBackProps) => {
    const { index, type, action } = props

    if (type === EVENTS.TOUR_END) {
      if (action === ACTIONS.SKIP) {
        window.dataLayer.push({ event: GoogleTagManagerEvent.onboarding, status: 'skipped' })
      } else if (action === ACTIONS.NEXT) {
        window.dataLayer.push({ event: GoogleTagManagerEvent.onboarding, status: 'completed' })
      }

      manageSettings.userGuide.completeUserGuide()

      if (userGuide.config.redirectOnCompletion) {
        history.push(userGuide.config.redirectOnCompletion)
      }
    }

    const redirect = userGuide.config.stepsRedirects[index]
    const shouldRedirect =
      type === EVENTS.STEP_BEFORE &&
      redirect &&
      location.pathname !== redirect

    if (shouldRedirect) {
      history.push(redirect)
    }
  }

  return (
    <Joyride
      steps={stepDefinitions}
      tooltipComponent={UserGuideTooltip}
      run={userGuide.enabled}
      callback={handleCallback}
      locale={userGuideLocale}
      data-testid="user-guide-window"
      disableScrolling
      disableCloseOnEsc
      disableOverlayClose
      showSkipButton
      continuous
      showProgress
      styles={{
        options: {
          // +1 higher than for Drawer and +1 for AppBar
          zIndex: zIndex.drawer + 2,
        }
      }}
    />
  )
}

export const UserGuide = React.memo(UserGuideBase)
