import React, { PropsWithChildren } from 'react'
import { Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: theme.typography.textPrimary.fontSize,
  minWidth: 300,
}))

export const ChartTooltip: React.FC<PropsWithChildren> = ({ children }) => (
  <StyledPaper elevation={3}>
    {children}
  </StyledPaper>
)
