export const GoogleTagManagerEvent = {
  login: 'login',
  logout: 'logout',
  onboarding: 'onboarding',
  subscriptionPlanSelect: 'subscription-plan-select',
  firstAssetCreated: 'first-asset-created',
  openedDemoAccountAsOnboarding: 'opened-demo-account-as-onboarding',
  openedWikiDocsAsOnboarding: 'opened-wiki-docs-as-onboarding',
  bookDemoClick: 'book-demo-click',
  shareWithAdvisorClick: 'share-with-advisor-click',
  publicCalculateRetirementClick: 'public-calculate-retirement-click',
}
