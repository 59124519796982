import { Stack } from '@mui/material'

import { DemoButton, AppFullLogoLink } from 'App/NavigationBar/components'
import { ComeBackAliveLinkLanding, DesktopLandingAnchorLinks, LoginButton } from 'App/NavigationBar/components-landing-shared'
import { landingSections } from './landingSections'
import { LanguageSelector } from './LanguageSelector'

export const DesktopNavigation = () => (
  <>
    <AppFullLogoLink />
    <DesktopLandingAnchorLinks sections={landingSections} />

    <Stack direction="row" alignItems="center" gap={1.5}>
      <ComeBackAliveLinkLanding />
      <DemoButton />
      <LoginButton />
      <LanguageSelector />
    </Stack>
  </>
)
