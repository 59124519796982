import { IconButton, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SettingsContext } from 'App/Settings'
import { StepName, UserGuideConfig } from 'App/UserGuide/types'
import { QuestionIcon } from 'Shared/assets'
import { Routes } from 'Shared/constants'

export const UserGuideButton: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { manageSettings } = useContext(SettingsContext)

  const isDemoMode = location.pathname.includes('demo')

  const stepRedirects = []
  stepRedirects[StepName.Welcome] = Routes.DEMO_HOLDINGS
  stepRedirects[StepName.Holdings] = Routes.DEMO_HOLDINGS
  stepRedirects[StepName.Summary] = Routes.DEMO_HOLDINGS
  stepRedirects[StepName.HiddenMode] = Routes.DEMO_HOLDINGS
  stepRedirects[StepName.Transactions] = Routes.DEMO_TRANSACTIONS
  stepRedirects[StepName.Analytics] = Routes.DEMO_ANALYTICS
  stepRedirects[StepName.Prediction] = Routes.DEMO_FORECAST
  stepRedirects[StepName.Final] = Routes.DEMO_HOLDINGS

  const userGuideConfig: UserGuideConfig = {
    displayWhenGuidePassedAlready: true,
    stepsRedirects: stepRedirects,
    redirectOnCompletion: !isDemoMode ? Routes.HOLDINGS : undefined,
  }

  return (
    <Tooltip title={t('app.intro.tooltip')}>
      <IconButton onClick={() => manageSettings.userGuide.runUserGuide(userGuideConfig)} size="small">
        <QuestionIcon width={16} height={16} />
      </IconButton>
    </Tooltip>
  )
}
