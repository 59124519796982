import { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { ProjectionRequest, IProjectionPlanService } from './projectionPlan-types'

export class ProjectionPlanService extends BaseService implements IProjectionPlanService {
  public buildProjection = (configuration: ProjectionRequest) => axios
    .post('/v2/forecast', { payload: configuration })
    .then((response: AxiosResponse) => response?.data)
}
