import { styled, Stack } from '@mui/material'

import { DesktopHeaderHeight, MobileHeaderHeight } from 'Shared/constants'

export const AppWrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexDirection: 'column',
  minWidth: 0,
  marginTop: DesktopHeaderHeight,
  [theme.breakpoints.down('sm')]: {
    // AppBar height + AppBarBreadcrumbs height
    marginTop: 56 + 56
  },
}))

export const StaticPageWrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexDirection: 'column',
  minWidth: 0,
  marginTop: DesktopHeaderHeight,
  [theme.breakpoints.down('mobileLarge')]: {
    marginTop: MobileHeaderHeight
  }
}))
