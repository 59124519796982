import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { localizeError } from 'Shared/utils'

interface Props {
  label: string
  placeholder: string
  value: any
  name?: string
  onChange: (value: any) => void
  error?: any
  prefixSymbol?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  inputComponent?: any
  inputProps?: any
}

// OBSOLETE: This component is not used anymore. Use CustomTextField2 instead.
export const CustomTextField: React.FC<Props> = (props: Props) => {
  const {
    label,
    placeholder,
    value,
    name,
    onChange,
    error,
    prefixSymbol,
    disabled,
    required,
    readOnly,
    inputComponent,
    inputProps,
  } = props

  const handleOnChange = (e: any) => {
    onChange(e.target.value)
  }

  return (
    <TextField
      label={label}
      value={value}
      data-testid={name}
      placeholder={placeholder}
      error={!!error}
      helperText={localizeError(error)}
      variant="outlined"
      size="small"
      margin="dense"
      disabled={disabled}
      required={required}
      fullWidth
      InputProps={{
        ...(prefixSymbol && { startAdornment: <InputAdornment position="start">{prefixSymbol}</InputAdornment> }),
        inputComponent: inputComponent || null,
        inputProps: {
          ...inputProps
        },
        readOnly,
      }}
      onChange={handleOnChange}
    />
  )
}
