import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { LandingNavigationBar } from 'App/NavigationBar'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { FaqStructuredData, OrganizationStructuredData } from './googleStructuredData'

const PortfolioTrackerPage = lazy(() => import('App/Landings/InvestorLandingPage/PortfolioTracker.page'))

export const PortfolioTrackerRoute: React.FC = () => (
  <>
    <Helmet>
      <title>FIREkit: Investment portfolio tracker</title>
      <script type="application/ld+json">{JSON.stringify(FaqStructuredData)}</script>
      <script type="application/ld+json">{JSON.stringify(OrganizationStructuredData)}</script>

      <meta name="title" content="FIREkit: Investment portfolio tracker" />
      <meta name="description" content="A simple solution to track your wealth online. Manage your investments assets, record transactions, and analyze your portfolio from different angles." />
      <link rel="canonical" href={process.env.REACT_APP_HOST} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop>
        <LandingNavigationBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <PortfolioTrackerPage />
          </Suspense>
          <LandingFooter />
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
)
