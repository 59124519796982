import React, { Suspense, lazy } from 'react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { PublicFunctionalAppBar } from 'App/NavigationBar'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { RetirementCalculatorWebAppStructuredData } from './googleStructuredData'

const PublicProjectionPage = lazy(() => import('ProjectionPlan/components/PublicProjectionPlan.page'))

export const PublicProjectionRoute = withTranslation()(({ t }) => (
  <>
    <Helmet>
      <title>FIREkit: Retirement calculator</title>
      <script type="application/ld+json">{JSON.stringify(RetirementCalculatorWebAppStructuredData)}</script>
      <meta name="title" content="FIREkit: Retirement calculator" />
      <meta name="description" content="Ready to retire early? Our retirement calculator makes retirement planning simple. Track assets, set spending goals, and achieve your financial independence!" />
      <link rel="canonical" href={`${process.env.REACT_APP_HOST}${Routes.PUBLIC.RETIREMENT_CALCULATOR}`} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop behavior="auto">
        <PublicFunctionalAppBar title={t('projection.seo-title')} />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <PublicProjectionPage />
            <LandingFooter />
          </Suspense>
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
))
