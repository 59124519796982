import React from 'react'
import type { TypographyProps } from '@mui/material'

import { Price } from 'Shared/components/Price'
import { TextPrimary } from 'Shared/components/Typography'
import { TooltipLabelValue } from './TooltipLabelValue'
import { TooltipLabel } from './TooltipLabel'

interface Props extends Omit<TypographyProps, 'color'> {
  label: string
  color?: string
  value: number
  currency: string
  isHighlighted?: boolean
  smartRound?: boolean
}

export const TooltipLabelPrice: React.FC<Props & TypographyProps> = props => {
  const {
    label,
    color,
    value,
    currency,
    isHighlighted,
    smartRound,
    ...typographyProps
  } = props

  const labelElement = React.useMemo(
    () => <TooltipLabel text={label} color={color} {...typographyProps} />,
    [ label, color, typographyProps ])

  const priceElement = React.useMemo(
    () => (
      <TextPrimary {...typographyProps}>
        <Price
          value={value}
          currency={currency}
          isHighlighted={!!isHighlighted}
          smartRound={!!smartRound}
        />
      </TextPrimary>),
    [ value, currency, isHighlighted, smartRound, typographyProps ])

  return <TooltipLabelValue label={labelElement} value={priceElement} />
}
