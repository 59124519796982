import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { AdvisorLandingNavigationBar } from 'App/NavigationBar'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { OrganizationStructuredData } from './googleStructuredData'

const ForAdvisorLandingPage = lazy(() => import('App/Landings/AdvisorLandingPage/ForAdvisor.page'))

export const ForFinancialAdvisorRoute: React.FC = () => (
  <>
    <Helmet>
      <title>FIREkit: Portfolio tracker for financial advisors</title>
      <script type="application/ld+json">{JSON.stringify(OrganizationStructuredData)}</script>

      <meta name="title" content="FIREkit: Portfolio tracker for financial advisors" />
      <meta name="description" content="A simple solution to track client portfolios online. Manage investment assets, record transactions, and analyze portfolios from different angles." />
      <link rel="canonical" href={`${process.env.REACT_APP_HOST}${Routes.LANDING.FOR_FINANCIAL_ADVISOR}`} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop>
        <AdvisorLandingNavigationBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <ForAdvisorLandingPage />
          </Suspense>
          <LandingFooter />
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
)
