import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

interface CustomTextFieldProps {
  name: string
}

export const CustomTextField2: React.FC<CustomTextFieldProps & TextFieldProps> = props => {
  const { name, ...otherProps } = props

  return (
    <TextField
      name={name}
      variant="outlined"
      size="small"
      fullWidth
      sx={{
        '.MuiOutlinedInput-input': {
          textOverflow: 'ellipsis',
          autocomplete: 'off',
        }
      }}
      data-lpignore="true"
      data-testid={name}
      {...otherProps}
    />
  )
}
