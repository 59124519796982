import React from 'react'
import { Stack } from '@mui/material'

import { ToggleHiddenMode, UserMenu } from 'App/NavigationBar/components'
import { NotificationButton } from 'App/Notifications/NotificationButton'
import { AppBarBreadcrumbs } from './AppBarBreadcrumbs'
import { ClientHistoryButton } from './ClientHistoryButton'

export const AppBarDesktopPanel: React.FC = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
  >
    <AppBarBreadcrumbs />

    <Stack flexDirection="row" alignItems="center" columnGap={1}>
      <ToggleHiddenMode />
      <ClientHistoryButton />
      <NotificationButton />
      <UserMenu />
    </Stack>
  </Stack>
)
