import React from 'react'

import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { MinimalisticNavigationBar } from 'App/NavigationBar'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { UnderMaintenance } from 'App/StaticPages'
import { AppFooter } from 'App/Footer/AppFooter'

export const UnderMaintenanceRoute: React.FC = () => (
  <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
    <MinimalisticNavigationBar />
    <StaticPageWrapper>
      <UnderMaintenance />
      <AppFooter />
    </StaticPageWrapper>
  </ViewModeProvider>
)
