import {
  ProjectionPeriodValue,
  ProjectionFinstrument,
  ProjectionGoal
} from 'Services/projectionPlan'

export type ProjectionConfigurationState = {
  name: string
  annualExpectedInflation: number
  annualSavingsGrowth: number
  finstruments: ProjectionFinstrument[]
  goals: ProjectionGoal[]

  hasChanges: boolean
  isLoading: boolean
  initialLoadingCompleted: boolean
}

export type ProjectionInsightsState = {
  values: ProjectionPeriodValue[]
  finstruments: ProjectionFinstrument[]
  goals: ProjectionGoal[]

  selectedPeriod: number
  selectedScale: number
  selectedDisplayMode: ProjectionChartMode

  isLoading: boolean
  initialLoadingCompleted: boolean
}

export type ProjectionInitialState = {
  configuration: ProjectionConfigurationState
  projection: ProjectionInsightsState
}

export enum ProjectionChartMode {
  FINSTRUMENTS = 'finstruments',
  RESULTS = 'results',
}

export enum ResultModeValues {
  SAVINGS = 'savings',
  RETURNS = 'returns'
}
