import { first } from 'lodash'
import { match } from 'ts-pattern'

import { ProjectionResponse, ProjectionGoalType } from 'Services/projectionPlan'
import type { ITypedAction } from 'Shared/types'
import { ProjectionInitialState } from './projectionPlan-types'
import { InitialState, ProjectionPlanActionTypes } from './projectionPlan-constants'

export const projectionPlan = (state: ProjectionInitialState = InitialState, action: ITypedAction<any>): ProjectionInitialState => {
  switch (action.type) {
    case ProjectionPlanActionTypes.LOAD_PROJECTION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          isLoading: true,
        }
      }
    }

    case ProjectionPlanActionTypes.LOAD_PROJECTION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          ...action.payload,
          isLoading: false,
        }
      }
    }

    case ProjectionPlanActionTypes.LOAD_PROJECTION_CONFIGURATION_FAILURE: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          isLoading: false,
        }
      }
    }

    case ProjectionPlanActionTypes.SAVE_PROJECTION_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          hasChanges: false,
        }
      }
    }

    case ProjectionPlanActionTypes.BUILD_PROJECTION: {
      return {
        ...state,
        projection: {
          ...state.projection,
          isLoading: true,
        }
      }
    }

    case ProjectionPlanActionTypes.BUILD_PROJECTION_SUCCESS: {
      const projection = action.payload as ProjectionResponse
      return {
        ...state,
        projection: {
          ...state.projection,
          values: projection.values,
          finstruments: projection.finstruments,
          goals: projection.goals,
          selectedPeriod: first(projection.values)?.period ?? InitialState.projection.selectedPeriod,
          isLoading: false,
          initialLoadingCompleted: true,
        },
      }
    }

    case ProjectionPlanActionTypes.BUILD_PROJECTION_FAILURE: {
      return {
        ...state,
        projection: {
          ...state.projection,
          isLoading: false,
          initialLoadingCompleted: true,
        }
      }
    }

    case ProjectionPlanActionTypes.UPDATE_PROJECTION_CONFIGURATION: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          name: action.payload.name,
          annualExpectedInflation: action.payload.annualExpectedInflation,
          annualSavingsGrowth: action.payload.annualSavingsGrowth,
          hasChanges: true,
        },
      }
    }

    case ProjectionPlanActionTypes.CREATE_PROJECTION_FINSTRUMENT: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          finstruments: [ ...state.configuration.finstruments, action.payload ],
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.UPDATE_PROJECTION_FINSTRUMENT: {
      return {
        ...state,
        configuration: {
          ...state.configuration,

          finstruments: state
            .configuration
            .finstruments
            .map(finstrument => finstrument.key === action.payload.key ? { ...finstrument, ...action.payload } : finstrument),

          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.DELETE_PROJECTION_FINSTRUMENT: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          finstruments: state
            .configuration
            .finstruments
            .filter(finstrument => finstrument.key !== action.payload),
          goals: state
            .configuration
            .goals
            .map(goal => match(goal)
              .with({ type: ProjectionGoalType.OneTime }, oneTimeGoal => ({ ...oneTimeGoal, finstrumentKeys: oneTimeGoal.finstrumentKeys.filter(key => key !== action.payload) }))
              .with({ type: ProjectionGoalType.Recurring }, recurringGoal => ({ ...recurringGoal, finstrumentKeys: recurringGoal.finstrumentKeys.filter(key => key !== action.payload) }))
              .with({ type: ProjectionGoalType.Retirement }, retirementGoal => ({ ...retirementGoal, finstrumentKeys: retirementGoal.finstrumentKeys.filter(key => key !== action.payload) }))
              .with({ type: ProjectionGoalType.ReachAmount }, reachAmountGoal => reachAmountGoal)
              .exhaustive()),
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.CREATE_PROJECTION_GOAL: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          goals: [ ...state.configuration.goals, action.payload ],
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.UPDATE_PROJECTION_GOAL: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          goals: state
            .configuration
            .goals
            .map(goal => goal.key === action.payload.key ? { ...goal, ...action.payload } : goal),
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.DELETE_PROJECTION_GOAL: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          goals: state
            .configuration
            .goals
            .filter(goal => goal.key !== action.payload),
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.TOGGLE_ACTIVE_PROJECTION_GOAL: {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          goals: state
            .configuration
            .goals
            .map(goal => goal.key === action.payload ? { ...goal, active: !goal.active } : goal),
          hasChanges: true,
        }
      }
    }

    case ProjectionPlanActionTypes.CHANGE_PROJECTION_PERIOD: {
      return {
        ...state,
        projection: {
          ...state.projection,
          selectedPeriod: action.payload,
        }
      }
    }

    case ProjectionPlanActionTypes.CHANGE_PROJECTION_SCALE: {
      return {
        ...state,
        projection: {
          ...state.projection,
          selectedScale: action.payload,
        }
      }
    }

    case ProjectionPlanActionTypes.CHANGE_PROJECTION_DISPLAY_MODE: {
      return {
        ...state,
        projection: {
          ...state.projection,
          selectedDisplayMode: action.payload,
        }
      }
    }

    default: {
      return state
    }
  }
}
