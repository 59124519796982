import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { match } from 'ts-pattern'

import { SupportedLanguage } from 'config/i18n-types'
import { useAuthWithDemo } from 'Demo'
import { container, Service } from 'Services/container'
import { IExchangeService, UnitType } from 'Services/exchange'
import { TickerName } from 'Shared/components/Ticker'
import { TextPrimary } from 'Shared/components/Typography'
import { InTextExternalLink } from 'Shared/components/Links'
import { ExternalLinks, PopularStocks, PopularCrypto, PopularCurrencies, SupportedCurrencies } from 'Shared/constants'
import { Nullable } from 'Shared/types'
import { CustomAsyncAutocomplete } from './CustomAsyncAutocomplete'
import { AutocompleteOption } from './NonForm-types'

interface Props {
  name: string
  label: string
  value: string | null
  onChange: (value: string | null) => void
  error: any
  unitType: UnitType
  onBlur?: () => void
  initialOptions?: AutocompleteOption[]
  includePopularOptions?: boolean
  disabled?: boolean
  required?: boolean
}

export const CustomTickerAutocomplete: React.FC<Props> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  unitType,
  initialOptions,
  includePopularOptions,
  disabled,
  required,
}) => {
  const { t, i18n } = useTranslation()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const [ isSearching, setIsSearching ] = React.useState(false)

  const supportedTickersHref = i18n.resolvedLanguage === SupportedLanguage.UK ?
    ExternalLinks.WIKI.FAQ_UK :
    ExternalLinks.WIKI.FAQ_EN

  const fetchOptions = React.useCallback(async (inputValue: string) => {
    const accessToken = await getAccessTokenWithDemo()
    const exchangeService = container.resolve<IExchangeService>(Service.ExchangeService)
    const units = await exchangeService.getUnits(accessToken, unitType, inputValue)
    return units.map(unit => ({ label: unit, value: unit }))
  }, [ unitType ])

  const placeholderValue = React.useMemo(
    () => match(unitType)
      .with(UnitType.Stocks, () => t('holdings.placeholders.tickerValue-stock', { value: 'VOO' }))
      .with(UnitType.CryptoCurrency, () => t('holdings.placeholders.tickerValue-crypto', { value: 'BTC' }))
      .with(UnitType.RegularCurrency, () => t('holdings.placeholders.tickerValue-currency', { value: 'GBP' }))
      .exhaustive(),
    [ unitType ])

  const noOptionsKeyByUnitType = React.useMemo(
    () => match(unitType)
      .with(UnitType.Stocks, () => 'common.stocks.supportedStocks-message')
      .with(UnitType.CryptoCurrency, () => 'common.crypto.supportedCrypto-message')
      .with(UnitType.RegularCurrency, () => 'common.money.supportedCurrency-message')
      .exhaustive(),
    [ unitType ])

  const options = React.useMemo(
    () => includePopularOptions ? [
      ...(initialOptions ?? []),
      ...(match(unitType)
        .with(UnitType.Stocks, () => PopularStocks.map(stock => ({ label: stock, value: stock, group: t('common.stocks.popular') })))
        .with(UnitType.CryptoCurrency, () => PopularCrypto.map(crypto => ({ label: crypto, value: crypto, group: t('common.crypto.popular') })))
        .with(UnitType.RegularCurrency, () => [
          ...PopularCurrencies
            .map(currency => ({ label: currency, value: currency, group: t('common.money.popular') })),
          ...SupportedCurrencies
            .filter(currency => !PopularCurrencies.includes(currency))
            .map(currency => ({ label: currency, value: currency, group: t('common.money.other') })),
        ])
        .run())
    ] : initialOptions,
    [ initialOptions, includePopularOptions ])

  const handleInputChange = (inputValue: Nullable<string>) => {
    setIsSearching(!!inputValue && inputValue !== value)
  }

  const handleOnChange = (value: Nullable<string>) => {
    onChange && onChange(value)
    setIsSearching(false)
  }

  const noOptionsText = React.useMemo(
    () => (
      <TextPrimary>
        {isSearching && <>{t('common.state.noOptions')}. </>}

        <Trans
          i18nKey={noOptionsKeyByUnitType}
          components={{
            explainLink: <InTextExternalLink href={supportedTickersHref} spaceBefore />
          }}
        />
      </TextPrimary>
    ),
    [ isSearching, unitType ]
  )

  return (
    <CustomAsyncAutocomplete
      name={name}
      initialOptions={options}
      label={label}
      placeholder={placeholderValue}
      value={value}
      onChange={handleOnChange}
      onInputChange={handleInputChange}
      onBlur={onBlur}
      error={error}
      disabled={disabled}
      required={required}
      fetchOptions={fetchOptions}
      extraProps={{
        renderOption: ({ key, ...props }: any, option: AutocompleteOption) => (
          <li key={key} {...props}>
            <TickerName value={option.value} />
          </li>
        ),
        noOptionsText: noOptionsText
      }}
    />
  )
}
