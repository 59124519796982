import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { LandingNavigationBar } from 'App/NavigationBar'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { FaqStructuredData, OrganizationStructuredData } from './googleStructuredData'

const DividendTrackerPage = lazy(() => import('App/Landings/InvestorLandingPage/DividendTracker.page'))

export const DividendTrackerRoute: React.FC = () => (
  <>
    <Helmet>
      <title>FIREkit: dividend tracker</title>
      <script type="application/ld+json">{JSON.stringify(FaqStructuredData)}</script>
      <script type="application/ld+json">{JSON.stringify(OrganizationStructuredData)}</script>

      <meta name="title" content="FIREkit: dividend tracker" />
      <meta name="description" content="Easily monitor payouts, analyze dividend growth, track portfolio performance, and optimize investments. Achieve your financial independence with FIREkit!" />
      <link rel="canonical" href={`${process.env.REACT_APP_HOST}${Routes.LANDING.DIVIDEND_TRACKER}`} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop>
        <LandingNavigationBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <DividendTrackerPage />
          </Suspense>
          <LandingFooter />
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
)
