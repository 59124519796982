import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Tooltip, IconButton } from '@mui/material'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'

import { SharingSelectors } from 'Sharing'
import { SettingsContext } from 'App/Settings'

export const ClientHistoryButton: React.FC = () => {
  const { t } = useTranslation()
  const { manageSettings } = useContext(SettingsContext)
  const sharingKey = useSelector(SharingSelectors.selectedSharingKey)

  if (!sharingKey) {
    return null
  }

  return (
    <Tooltip title={t('app.navbar.clientHistory')}>
      <IconButton onClick={() => manageSettings.sharedHistory.open(sharingKey)}>
        <HistoryOutlinedIcon fontSize="small" color="inherit" />
      </IconButton>
    </Tooltip>
  )
}
