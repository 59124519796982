import React, { PropsWithChildren } from 'react'
import { useMediaQuery, Theme, styled } from '@mui/material'

import { ContentPaper } from 'Shared/components/Papers'

interface StyledProps {
  stuck?: boolean
  topMobile: number
  topDesktop: number
}

const Container = styled(ContentPaper, {
  shouldForwardProp: prop =>
    prop !== 'topMobile' &&
    prop !== 'topDesktop' &&
    prop !== 'stuck' &&
    prop !== 'sx'
})<StyledProps>(({ theme, topMobile, topDesktop, stuck }) => ({
  borderRadius: stuck ? 0 : theme.spacing(0.5),
  marginLeft: stuck ? 0 : theme.spacing(2),
  marginRight: stuck ? 0 : theme.spacing(2),

  position: 'sticky',
  top: topDesktop,
  [theme.breakpoints.down('mobileLarge')]: {
    top: topMobile
  },
  zIndex: theme.zIndex.appBar,
  padding: 0,
  marginTop: theme.spacing(2),
}))

interface Props {
  topMobile: number
  topDesktop: number
  innerRef: React.RefObject<HTMLDivElement>
}

export const StickySubHeader: React.FC<PropsWithChildren<Props>> = ({
  children,
  topMobile,
  topDesktop,
  innerRef,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobileLarge'))
  const [ stuck, setStuck ] = React.useState(false)

  React.useEffect(() => {
    if (!innerRef.current) return

    const cachedRef = innerRef.current
    const observer = new IntersectionObserver(
      ([ e ]) => setStuck(e.intersectionRatio < 1),
      {
        threshold: 1,
        rootMargin: isMobile ?
          `-${topMobile + 1}px 0px 0px 0px` :
          `-${topDesktop + 1}px 0px 0px 0px`
      })

    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ innerRef, isMobile ])

  return (
    <Container stuck={stuck} topDesktop={topDesktop} topMobile={topMobile} ref={innerRef} elevation={0}>
      {children}
    </Container>
  )
}
