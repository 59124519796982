import { Stack } from '@mui/material'
import React from 'react'

import { ToggleHiddenMode, UserMenu } from 'App/NavigationBar/components'
import { TasksButton } from 'App/Tasks'
import { Permission, useAuthorization } from 'Integration/authorization'

export const AppBarMobileToolbar: React.FC = () => {
  const authorization = useAuthorization()

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      <ToggleHiddenMode />
      {authorization.hasAccess(Permission.ViewMyTasks) && <TasksButton />}
      <UserMenu />
    </Stack>
  )
}
