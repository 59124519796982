import { Stack } from '@mui/material'

import { Routes } from 'Shared/constants'
import { LanguageSelector } from 'App/NavigationBar/components-landing-bar/LanguageSelector'
import { ComeBackAliveLinkLanding, DesktopLandingAnchorLinks, LoginButton } from 'App/NavigationBar/components-landing-shared'
import { AppFullLogoLink } from 'App/NavigationBar/components'
import { landingAdvisorSections } from './landingAdvisorSections'

export const DesktopNavigationAdvisor = () => (
  <>
    <AppFullLogoLink linkTo={Routes.HOME} />
    <DesktopLandingAnchorLinks sections={landingAdvisorSections} />
    <Stack direction="row" alignItems="center" gap={1.5}>
      <ComeBackAliveLinkLanding />
      <LoginButton />
      <LanguageSelector />
    </Stack>
  </>
)
