import React, { PropsWithChildren } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { ApplicationLoadingOverlay } from 'Shared/components'

export const AuthenticationGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const auth0 = useAuth0()

  React.useEffect(() => {
    if (!auth0.isAuthenticated && !auth0.isLoading) {
      auth0.loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      })
    }
  }, [ auth0 ])

  if (!auth0.isAuthenticated) {
    return <ApplicationLoadingOverlay />
  }

  return <>{children}</>
}
