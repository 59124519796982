import React, { MouseEventHandler } from 'react'
import { IconButton, Menu, MenuList, IconButtonProps, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface Props extends Omit<IconButtonProps, 'onClick'> {
  menuOptions: { label: string, onClick: MouseEventHandler }[]
}

export const MoreMenu: React.FC<Props> = props => {
  const { menuOptions, ...iconButtonProps } = props
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [ setAnchorEl ])

  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [ setAnchorEl ])

  const handleMenuItemClick = React.useCallback((onClick: MouseEventHandler) =>
    (event: React.MouseEvent<HTMLElement>) => {
      onClick(event)
      handleClose()
    }, [ handleClose ])

  return (
    <>
      <IconButton
        aria-label="more"
        aria-expanded={anchorEl ? true : undefined}
        aria-haspopup="true"
        size="small"
        {...iconButtonProps}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuList dense>
          {menuOptions.map(({ label, onClick }) => (
            <MenuItem key={label} onClick={handleMenuItemClick(onClick)}>{label}</MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  )
}
