/* eslint-disable max-len */
export const RetirementCalculatorWebAppStructuredData = {
  ['@context']: 'https://schema.org',
  ['@type']: 'WebApplication',
  name: 'Retirement Calculator',
  description: 'An easy-to-use yet advanced retirement calculator to help you plan your financial future with FIREkit.',
  applicationCategory: 'FinanceApplication',
  browserRequirements: 'Requires JavaScript',
  operatingSystem: 'All',
  url: 'https://firekit.space/public/retirement-calculator',
  offers: {
    ['@type']: 'Offer',
    price: 0
  },
  publisher: {
    ['@type']: 'Organization',
    name: 'FIREkit',
    url: 'https://firekit.space',
    email: 'support@firekit.space'
  }
}

export const FireCalculatorWebAppStructuredData = {
  ['@context']: 'https://schema.org',
  ['@type']: 'WebApplication',
  name: 'FIRE Calculator',
  description: 'A financial independence calculator that helps you estimate when you can retire based on your savings, expenses, and investment growth.',
  applicationCategory: 'FinanceApplication',
  browserRequirements: 'Requires JavaScript',
  operatingSystem: 'All',
  url: 'https://firekit.space/public/fire-calculator',
  offers: {
    ['@type']: 'Offer',
    price: 0
  },
  publisher: {
    ['@type']: 'Organization',
    name: 'FIREkit',
    url: 'https://firekit.space',
    email: 'support@firekit.space'
  }
}
