import React, { PropsWithChildren } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { Link } from '@mui/material'

export interface InternalLinkProps extends Omit<LinkProps, 'to'> {
  to: string
}

export const InternalLink: React.FC<PropsWithChildren<InternalLinkProps>> = props => {
  const { to, children, ...linkProps } = props

  return (
    <Link
      component={RouterLink}
      underline="hover"
      fontSize="inherit"
      lineHeight="inherit"
      paddingBottom="inherit"
      {...linkProps}
      to={to}
    >
      {children}
    </Link>
  )
}
