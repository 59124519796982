import { RenewalPeriod } from 'Services/profile'
import { PricingType } from './pricing-types'

export const FreePricingCardConfiguration = {
  type: PricingType.Free,
  features: [
    { key: 'pricing.features.free.1', hint: 'pricing.features.free.1-hint' },
    { key: 'pricing.features.free.2', hint: 'pricing.features.free.2-hint' },
    { key: 'pricing.features.free.3', hint: 'pricing.features.free.3-hint' },
    { key: 'pricing.features.free.4', hint: 'pricing.features.free.4-hint' },
    { key: 'pricing.features.free.5' },
    { key: 'pricing.features.free.6' },
  ],
}

export const PremiumPricingCardConfiguration = {
  type: PricingType.Premium,
  price: {
    perMonth: 5.00,
    perYear: 60,
    defaultMonthlyDiscountedPrice: null,
    defaultYearlyDiscountedPrice: 50,
  },
  features: [
    { key: 'pricing.features.premium.1', hint: 'pricing.features.premium.1-hint' },
    { key: 'pricing.features.premium.2', hint: 'pricing.features.premium.2-hint' },
    { key: 'pricing.features.premium.3', hint: 'pricing.features.premium.3-hint' },
    { key: 'pricing.features.premium.4', hint: 'pricing.features.premium.4-hint' },
    { key: 'pricing.features.premium.5' },
    { key: 'pricing.features.premium.6' },
  ],
}

export const AdvisorPricingCardConfiguration = {
  type: PricingType.Free,
  features: [
    { key: 'pricing.features.advisor.1', hint: 'pricing.features.advisor.1-hint' },
    { key: 'pricing.features.advisor.2', hint: 'pricing.features.advisor.2-hint' },
    { key: 'pricing.features.advisor.3', hint: 'pricing.features.advisor.3-hint' },
    { key: 'pricing.features.advisor.4', hint: 'pricing.features.advisor.4-hint' },
    { key: 'pricing.features.advisor.5', hint: 'pricing.features.advisor.4-hint' },
  ],
}

export const FreePricingPlan = {
  [RenewalPeriod.Month]: {
    renewalPeriod: RenewalPeriod.Month,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: true,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.Year]: {
    renewalPeriod: RenewalPeriod.Year,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: true,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.Day]: {
    renewalPeriod: RenewalPeriod.Day,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: false,
    firstPeriodOnly: false,
  },
  [RenewalPeriod.None]: {
    renewalPeriod: RenewalPeriod.None,
    firstPeriodPrice: 0,
    nextPeriodPrice: 0,
    originalPrice: 0,
    periodPromoActive: false,
    autoRenew: false,
    firstPeriodOnly: false,
  }
}

export const YearDiscountTextKey = 'pricing.period.yearlyDiscount'
