import React from 'react'
import { AppBar, Stack, Toolbar, styled, useMediaQuery, Theme } from '@mui/material'

import { DesktopHeaderHeight, MobileHeaderHeight } from 'Shared/constants'
import { TextLarger } from 'Shared/components'
import { ToggleHiddenMode, AppShortLogoLink } from 'App/NavigationBar/components'
import { LoginButton } from 'App/NavigationBar/components-landing-shared'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  height: DesktopHeaderHeight,
  [theme.breakpoints.down('mobileLarge')]: {
    height: MobileHeaderHeight
  },
  borderBottom: `1px solid ${theme.palette.dark.dark3}`,
  boxSizing: 'content-box',
}))

interface Props {
  title: string
}

export const PublicFunctionalAppBar: React.FC<Props> = ({ title }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobileLarge'))

  return (
    <StyledAppBar color="inherit" elevation={0}>
      <Toolbar sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <AppShortLogoLink />
          <TextLarger component="h1" fontWeight={500}>{title}</TextLarger>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <ToggleHiddenMode />
          <LoginButton size={isMobile ? 'small' : 'medium'} />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  )
}
