import React, { Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'

import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { LandingNavigationBar } from 'App/NavigationBar'
import { LandingFooter } from 'App/Footer/LandingFooter'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { FaqStructuredData, OrganizationStructuredData } from './googleStructuredData'

const PortfolioVisualizerPage = lazy(() => import('App/Landings/InvestorLandingPage/PortfolioVisualizer.page'))

export const PortfolioVisualizerRoute: React.FC = () => (
  <>
    <Helmet>
      <title>FIREkit: Portfolio visualizer</title>
      <script type="application/ld+json">{JSON.stringify(FaqStructuredData)}</script>
      <script type="application/ld+json">{JSON.stringify(OrganizationStructuredData)}</script>

      <meta name="title" content="FIREkit: Portfolio visualizer" />
      <meta name="description" content="Visualize your portfolio, analyze asset allocation, track performance, and uncover insights to optimize investments. Simplify your strategy with FIREkit!" />
      <link rel="canonical" href={`${process.env.REACT_APP_HOST}${Routes.LANDING.VISUALIZE_PORTFOLIO}`} />
    </Helmet>

    <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
      <ScrollToTop>
        <LandingNavigationBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <PortfolioVisualizerPage />
          </Suspense>
          <LandingFooter />
        </StaticPageWrapper>
      </ScrollToTop>
    </ViewModeProvider>
  </>
)
