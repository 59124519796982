import MenuIcon from '@mui/icons-material/Menu'
import { IconButton, Stack } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { AppNavigation, ComeBackAlive } from 'App/NavigationBar/components'
import { AppLogoWhiteLabeled } from 'App/WhiteLabel/AppLogoWhiteLabeled'
import { Routes } from 'Shared/constants'
import { MobileHeaderHeight } from 'Shared/constants/spacing'
import type { NavigationLink } from 'Shared/types'
import * as S from './AppBar.style'

interface Props {
  links: NavigationLink[]
  toolbar: React.ReactNode
}

export const AppBarMobile: React.FC<PropsWithChildren<Props>> = ({ links, toolbar, children }) => {
  const [ open, setOpen ] = React.useState(false)

  const toggleMenu = () => setOpen(prevState => !prevState)

  const closeMenu = (event: React.KeyboardEvent | React.MouseEvent) => {
    const preventClose =
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')

    if (preventClose) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <S.StyledAppBar elevation={0}>
        <S.StyledToolBar>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              id="mobile-menu-button"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={toggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <S.AppLogoWrapper underline="none" component={RouterLink} to={Routes.HOME}>
              <AppLogoWhiteLabeled expanded />
            </S.AppLogoWrapper>
          </Stack>

          {toolbar}
        </S.StyledToolBar>
      </S.StyledAppBar>

      <S.AppBarBottomPanel>
        {children}
      </S.AppBarBottomPanel>

      <S.StyledDrawer
        open={open}
        onClose={closeMenu}
        anchor="left"
      >
        <S.StyledDivider sx={{ mt: `${MobileHeaderHeight}px` }} />
        <AppNavigation open={open} closeMenu={closeMenu} links={links} />
        <ComeBackAlive open={open} />
      </S.StyledDrawer>
    </>
  )
}
