import React from 'react'
import { AppBar, Toolbar } from '@mui/material'

import { AppFullLogoLink } from './components'

export const MinimalisticNavigationBar: React.FC = () => (
  <AppBar position="absolute" color="inherit" elevation={0}>
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <AppFullLogoLink />
    </Toolbar>
  </AppBar>
)
