import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Routes } from 'Shared/constants'
import { ApplicationLoadingOverlay, ScrollToTop } from 'Shared/components'
import { RouteResolver as AdvisorPortalResolver } from 'AdvisorPortal/RouteResolver'
import { AdvisorPortalDrawers } from 'AdvisorPortal/components/AdvisorPortalDrawers'
import { AppBar as AdvisorPortalAppBar } from 'AdvisorPortal/components/AppBar'
import { AppFooter, UserGuide } from 'App'
import { AppBar } from 'App/NavigationBar'
import { PaymentIssuesNotification } from 'App/Premium/PaymentIssuesNotification'
import { PromotionsSection } from 'App/Notifications/Promo'
import { NoMatch } from 'App/StaticPages'
import { ViewMode, ViewModeProvider } from 'App/ViewMode'
import { DemoNotification } from 'Demo'
import { AppBar as DemoAppBar } from 'Demo/AppBar'
import { Pricing } from 'Pricing'
import { DispatchSharingKey } from 'Sharing'
import { AuthenticationGuard } from './AuthenticationGuard'
import { ProtectedRoute } from './ProtectedRoute'
import { AppWrapper } from './ContentWrappers'
import {
  PortfolioTrackerRoute,
  StocksTrackerRoute,
  CryptoTrackerRoute,
  DividendTrackerRoute,
  PortfolioVisualizerRoute,
  ForFinancialAdvisorRoute,
} from './LandingRoutes'
import {
  UnderMaintenanceRoute,
  PaymentSuccessRoute,
  PaymentFailedRoute,
  ActivationSuccessRoute
} from './StaticRoutes'
import {
  PublicForecastRoute,
  PublicProjectionRoute
} from './PublicFunctional'

const AnalyticsPage = lazy(() => import('Analytics/components/Analytics.page'))
const HoldingsPage = lazy(() => import('Holdings/components/Holdings.page'))
const PredictionPage = lazy(() => import('Prediction/components/Prediction.page'))
const TransactionsPage = lazy(() => import('Transactions/components/Transactions.page'))
const AdvisorPortalPage = lazy(() => import('AdvisorPortal/components/AdvisorPortal.page'))

export const RouterBase: React.FC = () => (
  <Switch>
    <Route exact path={Routes.LANDING.HOME} component={PortfolioTrackerRoute} />
    <Route exact path={Routes.LANDING.STOCKS_TRACKER} component={StocksTrackerRoute} />
    <Route exact path={Routes.LANDING.CRYPTO_TRACKER} component={CryptoTrackerRoute} />
    <Route exact path={Routes.LANDING.DIVIDEND_TRACKER} component={DividendTrackerRoute} />
    <Route exact path={Routes.LANDING.VISUALIZE_PORTFOLIO} component={PortfolioVisualizerRoute} />

    <Redirect from="/advisor-home" to={Routes.LANDING.FOR_FINANCIAL_ADVISOR} />
    <Route exact path={Routes.LANDING.FOR_FINANCIAL_ADVISOR} component={ForFinancialAdvisorRoute} />

    <Route exact path={Routes.UNDER_MAINTENANCE} component={UnderMaintenanceRoute} />
    <Route exact path={Routes.PAYMENT_SUCCESS} component={PaymentSuccessRoute} />
    <Route exact path={Routes.PAYMENT_FAILED} component={PaymentFailedRoute} />
    <Route exact path={Routes.ACTIVATION_SUCCESS} component={ActivationSuccessRoute} />

    <Redirect from="/forecast" to={Routes.PUBLIC.FIRE_CALCULATOR} />
    <Route path={Routes.PUBLIC.FIRE_CALCULATOR} component={PublicForecastRoute} />
    <Redirect from="/projection" to={Routes.PUBLIC.RETIREMENT_CALCULATOR} />
    <Route path={Routes.PUBLIC.RETIREMENT_CALCULATOR} component={PublicProjectionRoute} />

    <Route path={Routes.DEMO}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <ScrollToTop behavior="auto">
          <DemoAppBar />
          <AppWrapper>
            <DemoNotification />
            <Suspense fallback={<ApplicationLoadingOverlay />}>
              <Switch>
                <Route exact path={Routes.DEMO_HOLDINGS} component={HoldingsPage} />
                <Route exact path={Routes.DEMO_TRANSACTIONS} component={TransactionsPage} />
                <Route exact path={Routes.DEMO_FORECAST} component={PredictionPage} />
                <Route path={Routes.DEMO_ANALYTICS} component={AnalyticsPage} />
                <Route exact path="*">
                  <Redirect to={Routes.NO_MATCH} />
                </Route>
              </Switch>
              <AppFooter />
            </Suspense>
          </AppWrapper>
          <UserGuide />
        </ScrollToTop>
      </ViewModeProvider>
    </Route>

    <Route path={Routes.PROFILE}>
      <AuthenticationGuard>
        <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
          <ScrollToTop behavior="auto">
            <AppBar />
            <AppWrapper>
              <PaymentIssuesNotification />
              <Suspense fallback={<ApplicationLoadingOverlay />}>
                <Switch>
                  <ProtectedRoute exact path={Routes.PRICING} component={Pricing} />
                  <ProtectedRoute exact path={Routes.HOLDINGS} component={HoldingsPage} />
                  <ProtectedRoute exact path={Routes.TRANSACTIONS} component={TransactionsPage} />
                  <ProtectedRoute exact path={Routes.FORECAST} component={PredictionPage} />
                  <ProtectedRoute path={Routes.ANALYTICS} component={AnalyticsPage} />
                  <Route exact path={`${Routes.PROFILE}/*`}>
                    <Redirect to={Routes.NO_MATCH} />
                  </Route>
                </Switch>
              </Suspense>
              <AppFooter />
            </AppWrapper>
            <UserGuide />
            <PromotionsSection />
          </ScrollToTop>
        </ViewModeProvider>
      </AuthenticationGuard>
    </Route>

    <Route path={Routes.ADVISOR_PORTAL}>
      <AuthenticationGuard>
        <AdvisorPortalResolver>
          <ViewModeProvider value={{ mode: ViewMode.Advisor }}>
            <ScrollToTop behavior="auto">
              <AdvisorPortalAppBar />
              <AppWrapper>
                <Suspense fallback={<ApplicationLoadingOverlay />}>
                  <Switch>
                    <ProtectedRoute exact path={Routes.ADVISOR_PORTAL} component={AdvisorPortalPage} />
                    <Route path={Routes.ADVISOR_PORTAL_SHARING}>
                      <DispatchSharingKey>
                        <Switch>
                          <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_HOLDINGS} component={HoldingsPage} />
                          <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_TRANSACTIONS} component={TransactionsPage} />
                          <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_FORECASTING} component={PredictionPage} />
                          <ProtectedRoute path={Routes.ADVISOR_PORTAL_SHARING_ANALYTICS} component={AnalyticsPage} />
                          <Route path={`${Routes.ADVISOR_PORTAL}/*`}>
                            <Redirect to={Routes.NO_MATCH} />
                          </Route>
                        </Switch>
                      </DispatchSharingKey>
                    </Route>
                    <Route exact path={`${Routes.ADVISOR_PORTAL}/*`}>
                      <Redirect to={Routes.NO_MATCH} />
                    </Route>
                  </Switch>
                </Suspense>
                <AppFooter />
              </AppWrapper>
              <AdvisorPortalDrawers />
            </ScrollToTop>
          </ViewModeProvider>
        </AdvisorPortalResolver>
      </AuthenticationGuard>
    </Route>

    <Route path="*" component={NoMatch} />
    <Route path={Routes.NO_MATCH} component={NoMatch} />
  </Switch>
)

export const Router = React.memo(RouterBase)
