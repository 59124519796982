import { Nullable } from 'Shared/types'

export interface IHoldingsService {
  getHoldings(token: string): any
  getHolding(key: string, token: string): any
  getSummary(token: string): any
  createHolding(token: string, payload: CreateHoldingRequest): any
  editHolding(key: string, token: string, payload: EditHoldingRequest): any
  archiveHolding(key: string, token: string): any
  deleteHolding(key: string, token: string): any
  pinHolding(key: string, token: string): any
  depositWithdraw(key: string, token: string, payload: DepositWithdrawRequest): any
  fixProfitAndLoss(key: string, token: string, payload: FixProfitLossRequest): any
  transfer(token: string, payload: TransferRequest): any
  buySellItem(key: string, token: string, payload: BuySellItemRequest): any
  changeTicker(key: string, token: string, payload: ChangeTickerRequest): any
  disconnectHolding(key: string, token: string): any
  refreshHolding(key: string, token: string): any
}

interface InitialTransaction {
  delta: number | null
  operationDate: Date | null
}

interface TickerTransaction {
  ticker: string
  price: number
  delta: number
  operationDate: Date
}

interface InitialTickerTransactions {
  tickers: TickerTransaction[]
}

interface HoldingMetadataRequest {
  country: string | null
  region: string | null
  class: string | null
  liquidity: string | null
  risk: string | null
  term: string | null
  dueDate: Date | null
}

export interface CreateHoldingRequest {
  holdingType: string,
  asset: {
    name: string
    company: string
  }
  category: string
  currency: Nullable<string>
  initialTransaction: InitialTransaction | null
  initialTickers: InitialTickerTransactions | null
  metadata: HoldingMetadataRequest
  financialData: {
    plannedProfitability: number
  }
  tags: string[]
  notes: string
}

export interface EditHoldingRequest {
  name: string
  company: string
  category: string
  metadata: HoldingMetadataRequest
  financialData: {
    plannedProfitability: number
  }
}

export interface DepositWithdrawRequest {
  delta: number
  commission: number
  currency: string
  comment: string
  operationDate: Date
}

export interface FixProfitLossRequestCashTransfer {
  holdingKey: string
  delta: number
  currency: string
}

export interface FixProfitLossRequest {
  delta: number
  currency: string
  operationDate: Date
  comment: string
  transfer: Nullable<FixProfitLossRequestCashTransfer>
}

export interface TransferRequest {
  from: string
  fromTicker: Nullable<string>
  fromCashAccount: Nullable<string>
  fromDelta: number
  to: string
  toTicker: Nullable<string>
  toCashAccount: Nullable<string>
  toDelta: number
  commission: number
  operationDate: Date
  comment: string
}

export interface BuySellItemRequest {
  currency: string
  ticker: string
  delta: number
  price: number
  commission: number
  operationDate: Date
  comment: string
  useCashDeposit: boolean
}

export interface ChangeTickerRequest {
  fromTicker: string
  toTicker: string
}

export interface Asset {
  name: string
  company: string
}

export enum HOLDING_TYPE {
  Custom = 'Custom',
  Crypto = 'Crypto',
  Stock = 'Stock',
}

export enum HOLDING_TERM {
  LessThanHalfOfYear = 'LessThanHalfOfYear',
  HalfToOneYear = 'HalfToOneYear',
  OneToFiveYears = 'OneToFiveYears',
  FiveToTenYears = 'FiveToTenYears',
  MoreThanTenYears = 'MoreThanTenYears',
}

export enum HOLDING_CLASS {
  Cash = 'Cash',
  Securities = 'Securities',
  Bonds = 'Bonds',
  RealEstate = 'RealEstate',
  Commodities = 'Commodities',
  BusinessInvestments = 'BusinessInvestments',
  VentureCapital = 'VentureCapital',
  Cryptocurrency = 'Cryptocurrency',
  Tangible = 'Tangible',
  Intangible = 'Intangible',
  Alternatives = 'Alternatives',
  PrivateDebt = 'PrivateDebt',
  PreciousMetals = 'PreciousMetals',
}

export enum HOLDING_LIQUIDITY {
  UrgentlyLiquid = 'UrgentlyLiquid',
  HighlyLiquid = 'HighlyLiquid',
  MediumLiquid = 'MediumLiquid',
  WeaklyLiquid = 'WeaklyLiquid',
  HardToLiquid = 'HardToLiquid',
}

export enum HOLDING_RISK {
  Min = 'Min',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Max = 'Max',
}

export type HoldingRegion = AmericasRegion | GreaterEuropeRegion | GreaterAsiaRegion

export enum AmericasRegion {
  NorthAmerica = 'NorthAmerica',
  LatinAmerica = 'LatinAmerica',
}

export enum GreaterEuropeRegion {
  UnitedKingdom = 'UnitedKingdom',
  EuropeDeveloped = 'EuropeDeveloped',
  EuropeEmerging = 'EuropeEmerging',
  AfricaMiddleEast = 'AfricaMiddleEast',
}

export enum GreaterAsiaRegion {
  Japan = 'Japan',
  Australasia = 'Australasia',
  AsiaDeveloped = 'AsiaDeveloped',
  AsiaEmerging = 'AsiaEmerging',
}

export interface HoldingMetaData {
  class: HOLDING_CLASS | null
  liquidity: HOLDING_LIQUIDITY | null
  risk: HOLDING_RISK | null
  term: HOLDING_TERM | null
  country: string | null
  region: HoldingRegion | null
  dueDate: string | null
}

export interface Profitability {
  percent: number | null
  value: number
}

export interface HoldingFinancialData {
  plannedProfitability: number | null
  totalProfitability: Profitability
  averageProfitability: Profitability
  unrealizedProfitability: Profitability | null
  unrealizedDailyProfitability: Profitability | null
  realizedProfitability: Profitability | null
}

export interface HoldingItemDetails {
  currency: string
  price: number
  averagePrice: number
  totalSpent: number

  totalProfitability: {
    value: number,
    percent: number | null,
  },
  unrealizedProfitability: {
    value: number,
    percent: number | null,
  }
  unrealizedDailyProfitability: {
    value: number,
    percent: number | null,
  }
}

export interface HoldingItem {
  ticker: string
  balance: number

  defaultCurrencyDetails: HoldingItemDetails
  baseCurrencyDetails: HoldingItemDetails

  externalDescription: string
  isSupported: boolean
}

export interface CashAccount {
  currency: string
  balance: number
}

export interface ExternalConnection {
  key: string
  errorState: boolean
  needsReauthentication: boolean
  syncEnabled: boolean
  lastSync: Date | null
}

export interface HoldingResponse {
  type: HOLDING_TYPE
  asset: Asset
  balance: Nullable<number>
  baseCurrencyNetWorth: number
  baseCurrencyCashNetWorth: number
  category: string
  createdOn: string
  currency: string
  key: string
  lastBalanceChanges: string
  pinDateTime: Nullable<string>
  profitLossFromMonthStart: number
  metadata: HoldingMetaData
  financialData: HoldingFinancialData
  items: Nullable<HoldingItem[]>
  cashAccounts: Nullable<CashAccount[]>
  notes: string
  tags: string[]
  externalConnection: ExternalConnection
}

export interface SummaryProfitability {
  allTimeXirr: number | null
  allTimeTwr: number | null
  monthToDateTwr: number | null
  yearToDateTwr: number | null
  lastYearTwr: number | null
  unrealized: Profitability | null
  unrealizedDaily: Profitability | null
  planned: number | null
}

export interface Summary {
  totalInBaseCurrency: number
  profitLossFromYearStartInBaseCurrency: number
  profitLossFromMonthStartInBaseCurrency: number
  balanceChangeFromYearStartInBaseCurrency: number
  balanceChangeFromMonthStartInBaseCurrency: number
  profitability: SummaryProfitability
}

export enum ExternalConnectionStatus {
  Success,
  Warning,
  Error,
}
