import React from 'react'
import { Stack, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { TextPrimary } from 'Shared/components/Typography'

interface Props {
  label: string
  color?: string
  onClick?: () => void
}

export const LegendItem: React.FC<Props> = ({ label, color, onClick }) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    spacing={1}
    onClick={onClick}
    sx={{ cursor: onClick ? 'pointer' : 'auto' }}
  >
    {color && <CircleIcon sx={{ color: color, fontSize: 16 }} />}
    <TextPrimary>{label}</TextPrimary>
  </Stack>
)
