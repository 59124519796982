import React, { PropsWithChildren, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
  behavior?: 'auto' | 'smooth'
}

export const ScrollToTop: React.FC<PropsWithChildren<Props>> = ({ children, behavior = 'smooth' }) => {
  const history = useHistory()

  useEffect(() => {
    const unListen = history.listen(location => {
      if (!location.hash) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: behavior
        })
      }
    })

    return () => unListen()
  }, [])

  return <>{children}</>
}
