import React from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete, TextField, AutocompleteRenderInputParams } from '@mui/material'

import { Tag } from 'Shared/components/Tag'
import { AutocompleteOption } from 'Shared/components/NonForm'

interface Props {
  label: string
  value: AutocompleteOption[]
  options: AutocompleteOption[],
  onChange: (value: AutocompleteOption[]) => void
  autocompleteProps?: any
  inputProps?: any
  tagsProps?: any
}

export const CustomFixedTagsAutocomplete: React.FC<Props> = ({
  label,
  value,
  onChange,
  options,
  autocompleteProps,
  inputProps,
  tagsProps,
}) => {
  const { t } = useTranslation()

  const handleOnChange = React.useCallback((_: any, options: AutocompleteOption[]) => {
    onChange(options)
  }, [ onChange ])

  const renderTags = React.useCallback((value: AutocompleteOption[], getTagProps: Function) => value
    .map((option, index) => (
      <Tag
        {...getTagProps({ index })}
        key={index}
        label={option.label}
        size="small"
        {...tagsProps}
      />
    )), [])

  const renderInput = React.useCallback((params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      {...inputProps}
    />
  ), [])

  const getOptionLabel = React.useCallback((option: AutocompleteOption) => option.label, [])
  const getOptionDisabled = React.useCallback((option: AutocompleteOption) => option?.disabled, [])
  const isOptionEqualToValue = React.useCallback((option: AutocompleteOption, value: AutocompleteOption) =>
    option.value.trim().toLowerCase() === value.value.trim().toLowerCase(), [])

  return (
    <Autocomplete
      multiple
      autoHighlight
      fullWidth
      value={value}
      onChange={handleOnChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      renderTags={renderTags}
      renderInput={renderInput}
      noOptionsText={t('common.state.noOptions')}
      clearText={t('common.actions.clear')}
      size="small"
      {...autocompleteProps}
    />
  )
}
