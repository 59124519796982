import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, ButtonProps, styled } from '@mui/material'

import { Routes } from 'Shared/constants'

export const LoginButton: React.FC<ButtonProps> = props => {
  const { t } = useTranslation()

  return (
    <Button
      component={Link}
      to={Routes.HOLDINGS}
      variant="contained"
      data-testid="login-button"
      {...props}
    >
      {t('app.navbar.landing.login')}
    </Button>
  )
}
