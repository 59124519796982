import React from 'react'
import { TypographyProps, Stack } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

import { TextPrimary } from 'Shared/components/Typography'

interface Props {
  text: string
  color?: string
}

export const TooltipLabel: React.FC<Props & TypographyProps> = props => {
  const { text, color, ...typographyProps } = props

  if (color) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <CircleIcon fontSize="inherit" sx={{ color }} />
        <TextPrimary noWrap {...typographyProps}>{text}:</TextPrimary>
      </Stack>
    )
  }

  return <TextPrimary noWrap {...typographyProps}>{text}:</TextPrimary>
}
