import React, { useContext } from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { theme } from 'config/theme'
import { languageService, SupportedLanguage } from 'config/i18n-types'
import { SecureNumber } from 'Shared/components/SecureNumber'

interface PercentProps {
  value: number | null
  isHighlighted?: boolean
  inline?: boolean
  hideSign?: boolean
}

const getSign = (value: number) => {
  if (value < 0) return '▼\xa0'
  if (value > 0) return '▲\xa0'

  return ''
}

const getColor = (value: number, isHighlighted: boolean) => {
  if (value === 0) {
    return theme.palette.dark.dark1
  }

  if (isHighlighted) {
    if (value < 0) return theme.palette.error.light
    if (value > 0) return theme.palette.success.light
  }

  return ''
}

const PercentContainer = styled(Typography)({
  fontSize: 'inherit',
  lineHeight: 'inherit',
}) as typeof Typography

const UndefinedPercentContainer = styled(PercentContainer)(({ theme }) => ({
  color: theme.palette.dark.dark1
})) as typeof Typography

export const Percent: React.FC<PercentProps> = React.memo(({
  value,
  isHighlighted = false,
  inline = false,
  hideSign = false,
}) => {
  const { t, i18n } = useTranslation()

  if (value === null) {
    return (
      <SecureNumber>
        <UndefinedPercentContainer color="disabled" component="span">
          {t('common.undefined')}
        </UndefinedPercentContainer>
      </SecureNumber>
    )
  }

  const language = i18n.resolvedLanguage as SupportedLanguage
  const absolutePriceValue = Math.abs(value)
  const color = getColor(value, isHighlighted)

  const percent = {
    sign: getSign(value),
    value: (Math.round(absolutePriceValue * 100) / 100).toLocaleString(languageService.getLocale(language).code)
  }

  return (
    <SecureNumber>
      <PercentContainer
        component="span"
        fontWeight="inherit"
        color={color}
        title={`${percent.sign}${percent.value}%`}
        display={inline ? 'inline' : 'block'}
        noWrap
      >
        {!hideSign && percent.sign}
        {percent.value}%
      </PercentContainer>
    </SecureNumber>
  )
})
