import { ILocalStorageService } from './localStorage-types'

export class LocalStorageService implements ILocalStorageService {
  public setItem = (key: string, value: any) => {
    try {
      const jsonValue = JSON.stringify(value)
      localStorage.setItem(key, jsonValue)
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }

  public getItem = <T>(key: string) => {
    try {
      const stringValue = localStorage.getItem(key)
      return stringValue ? JSON.parse(stringValue) as T : null
    } catch (error) {
      return null
    }
  }

  public removeItem = (key: string) => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }

  public clear = () => {
    try {
      localStorage.clear()
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }
}
