import React, { PropsWithChildren } from 'react'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

export const Auth0ProviderWithHistory: React.FC<PropsWithChildren> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
  const audience = process.env.REACT_APP_AUTH0_API_AUDIENCE

  const history = useHistory()

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState?.returnTo) {
      history.push(appState?.returnTo)
    }
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope="openid profile"
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}
