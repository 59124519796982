import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TextBig, TextBigger, TextLarge, fontOpenSans, fontRubik } from 'Shared/components'
import React, { PropsWithChildren } from 'react'

export const BaseTypography = styled(Typography)({
  fontWeight: 300
})

export const Header = styled(BaseTypography)({
  fontSize: '2.5rem',
  textTransform: 'uppercase'
})

export const Paragraph = styled(BaseTypography)({
  textAlign: 'justify',
  fontWeight: 300,
  margin: '10px 0'
})

export const StaticPageTitle = styled(Typography)(({ theme }) => ({
  fontFamily: fontRubik,
  fontSize: 40,
  fontWeight: 800,

  [theme.breakpoints.down('tablet')]: {
    fontSize: theme.typography.textBig.fontSize,
  },
}))

export const StaticPageSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: fontOpenSans,
  fontSize: theme.typography.textLarge.fontSize
}))

export const MainTitleStyled = styled(Typography)(({ theme }) => ({
  fontFamily: fontRubik,
  fontSize: 56,
  fontWeight: 800,
  lineHeight: '66px',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('tabletLandscape')]: {
    fontSize: 48,
    lineHeight: '52px',
  },
  [theme.breakpoints.down('tablet')]: {
    fontSize: 40,
    lineHeight: '44px',
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: 36,
    lineHeight: '40px',
  },
})) as typeof Typography

export const MainTitleAccentStyled = styled(MainTitleStyled)(({ theme }) => ({
  color: theme.palette.secondary.main
})) as typeof Typography

interface MainTitleProps {
  component?: React.ElementType
}

export const MainTitle: React.FC<PropsWithChildren<MainTitleProps>> = ({ component = 'h1', children }) => (
  <MainTitleStyled component={component}>
    {children}
  </MainTitleStyled>
)

export const MainTitleAccent: React.FC<PropsWithChildren> = ({ children }) => (
  <MainTitleAccentStyled component="span">
    {children}
  </MainTitleAccentStyled>
)

export const Title: React.FC<PropsWithChildren> = ({ children }) => (
  <MainTitleStyled>
    {children}
  </MainTitleStyled>
)

export const SectionTitleStyled = styled(Typography)(({ theme }) => ({
  fontFamily: fontRubik,
  fontSize: 40,
  fontWeight: 800,
  lineHeight: '48px',
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('tablet')]: {
    fontSize: 36,
    lineHeight: '42px',
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: 32,
    lineHeight: '38px',
  },
})) as typeof Typography

export const SectionTitleAccentStyled = styled(SectionTitleStyled)(({ theme }) => ({
  color: theme.palette.secondary.main
})) as typeof Typography

export const SectionTitleHardAccentStyled = styled(SectionTitleStyled)(({ theme }) => ({
  color: theme.palette.danger.danger1
})) as typeof Typography

export const SectionTitle: React.FC<PropsWithChildren> = ({ children }) => (
  <SectionTitleStyled component="h2">
    {children}
  </SectionTitleStyled>
)

export const SectionTitleAccent: React.FC<PropsWithChildren> = ({ children }) => (
  <SectionTitleAccentStyled component="span">
    {children}
  </SectionTitleAccentStyled>
)

export const SectionTitleHardAccent: React.FC<PropsWithChildren> = ({ children }) => (
  <SectionTitleHardAccentStyled component="span">
    {children}
  </SectionTitleHardAccentStyled>
)

export const SectionSubTitleAccent = styled(TextBig)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontFamily: fontOpenSans,
  fontWeight: 700,
  marginBottom: theme.spacing(2),
})) as typeof Typography

export const Description = styled(TextLarge)(() => ({
  fontFamily: fontOpenSans,
}))

// TODO: move to respective components
export const FaqTitle = styled(TextBig)(({ theme }) => ({
  color: theme.palette.common.black,
  fontFamily: fontRubik,
  fontWeight: 800,
  marginBottom: theme.spacing(5),
}))

export const FeatureTitle = styled(TextBigger)(({ theme }) => ({
  fontFamily: fontRubik,
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  whiteSpace: 'pre-line'
})) as typeof Typography
