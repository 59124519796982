import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import { SettingsContext } from 'App/Settings'
import { EyeIcon, EyeSlashIcon } from 'Shared/assets'
import { TextPrimary } from 'Shared/components/Typography'

const HiddenModeWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  color: 'inherit',
}))

const StyledLabel = styled(TextPrimary)(({ theme }) => ({
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
}))

const StyledButton = styled(Button)({
  minWidth: 0,
  color: 'inherit',
  ':hover': {
    backgroundColor: 'unset'
  },
})

export const ToggleHiddenMode = () => {
  const { t } = useTranslation()
  const { isHiddenMode, manageSettings } = useContext(SettingsContext)

  const VisibleMode = (
    <HiddenModeWrapper>
      <EyeSlashIcon width={18} />
      <StyledLabel>{t('app.navbar.hiddenMode-hide')}</StyledLabel>
    </HiddenModeWrapper>
  )

  const HiddenMode = (
    <HiddenModeWrapper>
      <EyeIcon width={18} />
      <StyledLabel>{t('app.navbar.hiddenMode-show')}</StyledLabel>
    </HiddenModeWrapper>
  )

  return (
    <StyledButton
      size="small"
      data-userguide-id="stepHiddenMode"
      onClick={manageSettings.toggleHiddenMode}
      disableRipple
    >
      {isHiddenMode ? HiddenMode : VisibleMode}
    </StyledButton>
  )
}
