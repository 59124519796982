import React from 'react'
import Linkify from 'linkify-react'

import { ButtonLink } from 'Shared/components/Links'
import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  limit: number
}

export const ExpandMoreText: React.FC<Props> = ({ text, limit }) => {
  const { t } = useTranslation()
  const [ expanded, setExpanded ] = React.useState(false)
  const showMore = text.length > limit

  const shortText = showMore ? `${text.substring(0, limit)}...` : text

  return (
    <>
      <Linkify options={{ target: '_blank', rel: 'noopener nofollow' }}>
        {expanded ? text : shortText}
      </Linkify>

      {showMore && (
        <ButtonLink onClick={() => setExpanded(!expanded)} sx={{ textTransform: 'lowercase' }}>
          &nbsp;{expanded ? t('common.actions.viewLess') : t('common.actions.viewMore')}
        </ButtonLink>
      )}
    </>
  )
}
