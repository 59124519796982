import React, { PropsWithChildren } from 'react'
import { Alert, AlertProps } from '@mui/material'

import { SubTextPrimary1 } from 'Shared/components/Typography'

export const FinePrint: React.FC<PropsWithChildren<AlertProps>> = props => {
  const { children, ...alertProps } = props

  return (
    <Alert variant="outlined" severity="warning" {...alertProps}>
      <SubTextPrimary1>{children}</SubTextPrimary1>
    </Alert>
  )
}
