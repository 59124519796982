export type ProjectionFinstrument = {
  key: string
  name: string
  initialBalance: number
  annualSavingsAmount: number
  annualProfitability: number
  dividendTaxRate: number
  withdrawalTaxRate: number
}

export enum ProjectionGoalType {
  OneTime = 'OneTime',
  Recurring = 'Recurring',
  Retirement = 'Retirement',
  ReachAmount = 'ReachAmount',
}

type BaseGoal = {
  type: ProjectionGoalType

  key: string
  name: string
  emoji: string
  active: boolean
}

export type OneTimeGoal = BaseGoal & {
  type: ProjectionGoalType.OneTime

  finstrumentKeys: string[]
  amount: number
  startDate: Date
}

export type RecurringGoal = BaseGoal & {
  type: ProjectionGoalType.Recurring

  finstrumentKeys: string[]
  amount: number
  startDate: Date
  endDate: Date | null
  everyPeriod: number
}

export type RetirementGoal = BaseGoal & {
  type: ProjectionGoalType.Retirement

  finstrumentKeys: string[]
  amount: number
  startDate: Date
  retirementProfitability: number
}

export type ReachAmountGoal = BaseGoal & {
  type: ProjectionGoalType.ReachAmount

  amount: number
}

export type ProjectionGoal = OneTimeGoal | RecurringGoal | RetirementGoal | ReachAmountGoal

export type ProjectionRequest = {
  name: string
  annualExpectedInflation: number
  annualSavingsGrowth: number
  finstruments: ProjectionFinstrument[]
  goals: ProjectionGoal[]
}

export enum AchievedGoalType {
  Spending = 'Spending',
  ReachAmount = 'ReachAmount',
}

export type ProjectionAchievedGoalBase = {
  key: string
  date: Date
  type: AchievedGoalType
}

export type ProjectionAchievedSpendingGoal = ProjectionAchievedGoalBase & {
  type: AchievedGoalType.Spending

  originalAmount: number
  inflatedAmount: number
  fundingBreakdown: { [key: string]: number }
}

export type ProjectionAchievedAmountGoal = ProjectionAchievedGoalBase & {
  type: AchievedGoalType.ReachAmount

  amount: number
}

export type ProjectionPlannedGoal = {
  key: string
  date: Date
}

export type ProjectionBreakdown = {
  finstrumentKey: string
  accumulatedSavings: number
  accumulatedReturns: number
  savings: number
  returns: number
  profitability: number
  inflation: number
}

export type ProjectionPeriodValue = {
  period: number
  accumulatedSavings: number
  accumulatedReturns: number
  savings: number
  returns: number
  dividendTaxes: number
  withdrawalTaxes: number

  achievedSpendingGoals: ProjectionAchievedSpendingGoal[]
  achievedAmountGoals: ProjectionAchievedAmountGoal[]
  plannedGoals: ProjectionPlannedGoal[]
  breakdown: ProjectionBreakdown[]
}

export type ProjectionResponse = {
  values: ProjectionPeriodValue[]
  finstruments: ProjectionFinstrument[]
  goals: ProjectionGoal[]
}

export interface IProjectionPlanService {
  buildProjection(configuration: ProjectionRequest): Promise<ProjectionResponse>
}
