import MenuIcon from '@mui/icons-material/Menu'
import { Drawer, IconButton } from '@mui/material'
import { useState } from 'react'

import { AppFullLogoLink } from 'App/NavigationBar/components'
import { LoginButton, MobileLandingAnchorLinks } from 'App/NavigationBar/components-landing-shared'
import { landingSections } from './landingSections'
import { LanguageSelector } from './LanguageSelector'

export const MobileNavigation = () => {
  const [ open, setOpen ] = useState(false)
  const toggleMenuOpenClose = () => setOpen(prevState => !prevState)

  const closeMenu = (event: React.KeyboardEvent | React.MouseEvent) => {
    const preventClose =
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')

    if (preventClose) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        id="mobile-menu-button"
        aria-controls="mobile-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={toggleMenuOpenClose}
      >
        <MenuIcon />
      </IconButton>
      <AppFullLogoLink />
      <LoginButton size="small" />

      <Drawer
        open={open}
        onClose={closeMenu}
        anchor="left"
      >
        <>
          <MobileLandingAnchorLinks sections={landingSections} onClose={closeMenu} />
          <LanguageSelector />
        </>
      </Drawer>
    </>
  )
}
