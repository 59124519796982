import { ProjectionGoalType } from 'Services/projectionPlan'
import { ProjectionInitialState, ProjectionChartMode } from './projectionPlan-types'

export const InitialState: ProjectionInitialState = {
  configuration: {
    name: 'Unknown',
    annualSavingsGrowth: 0,
    annualExpectedInflation: 0,
    finstruments: [],
    goals: [],

    hasChanges: false,
    isLoading: false,
    initialLoadingCompleted: false,
  },
  projection: {
    values: [],
    finstruments: [],
    goals: [],

    selectedPeriod: new Date().getFullYear(),
    selectedDisplayMode: ProjectionChartMode.FINSTRUMENTS,
    selectedScale: 50,

    isLoading: false,
    initialLoadingCompleted: false,
  },
}

export const ProjectionPlanActionTypes = {
  LOAD_PROJECTION_CONFIGURATION: 'LOAD_PROJECTION_CONFIGURATION',
  LOAD_PROJECTION_CONFIGURATION_SUCCESS: 'LOAD_PROJECTION_CONFIGURATION_SUCCESS',
  LOAD_PROJECTION_CONFIGURATION_FAILURE: 'LOAD_PROJECTION_CONFIGURATION_FAILURE',
  SAVE_PROJECTION_CONFIGURATION_SUCCESS: 'SAVE_PROJECTION_CONFIGURATION_SUCCESS',

  BUILD_PROJECTION: 'BUILD_PROJECTION',
  BUILD_PROJECTION_SUCCESS: 'BUILD_PROJECTION_SUCCESS',
  BUILD_PROJECTION_FAILURE: 'BUILD_PROJECTION_FAILURE',

  UPDATE_PROJECTION_CONFIGURATION: 'UPDATE_PROJECTION_CONFIGURATION',

  CREATE_PROJECTION_FINSTRUMENT: 'CREATE_PROJECTION_FINSTRUMENT',
  UPDATE_PROJECTION_FINSTRUMENT: 'UPDATE_PROJECTION_FINSTRUMENT',
  DELETE_PROJECTION_FINSTRUMENT: 'DELETE_PROJECTION_FINSTRUMENT',

  CREATE_PROJECTION_GOAL: 'CREATE_PROJECTION_GOAL',
  UPDATE_PROJECTION_GOAL: 'UPDATE_PROJECTION_GOAL',
  DELETE_PROJECTION_GOAL: 'DELETE_PROJECTION_GOAL',
  TOGGLE_ACTIVE_PROJECTION_GOAL: 'TOGGLE_ACTIVE_PROJECTION_GOAL',

  CHANGE_PROJECTION_PERIOD: 'CHANGE_PROJECTION_PERIOD',
  CHANGE_PROJECTION_SCALE: 'CHANGE_PROJECTION_SCALE',
  CHANGE_PROJECTION_DISPLAY_MODE: 'CHANGE_PROJECTION_DISPLAY_MODE',
}

export const GoalTypeTranslations = {
  [ProjectionGoalType.OneTime]: 'projection.goals.fields.types.oneTime',
  [ProjectionGoalType.Recurring]: 'projection.goals.fields.types.recurring',
  [ProjectionGoalType.Retirement]: 'projection.goals.fields.types.retirement',
  [ProjectionGoalType.ReachAmount]: 'projection.goals.fields.types.reachAmount',
}
