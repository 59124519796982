import React from 'react'

export const useFormDirtyDetector = () => {
  const [ isFormDirty, setIsFormDirty ] = React.useState(false)

  const setFormDirty = React.useCallback(() => { setIsFormDirty(true) }, [ ])
  const resetFormDirty = React.useCallback(() => { setIsFormDirty(false) }, [ ])

  return {
    isFormDirty,
    setFormDirty,
    resetFormDirty,
  }
}
