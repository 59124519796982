export const PopularStocks = [
  'NYSE:VOO',
  'NASDAQ:AAPL',
  'NYSE:VTI',
  'NASDAQ:TLT',
  'NYSE:SPY',
  'NYSE:SCHD',
  'NYSE:VT',
  'NASDAQ:QQQ',
  'NYSE:IVV',
  'NASDAQ:TSLA',
  'NASDAQ:MSFT',
  'NASDAQ:AMZN',
  'LSE:SWRD',
  'NASDAQ:NVDA',
  'NASDAQ:GOOG',
  'NASDAQ:BND',
  'NYSE:ITOT',
  'LSE:CSPX',
  'NASDAQ:VXUS',
  'NYSE:O',
]

export const PopularCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
]

export const PopularCrypto = [
  'BTC',
  'ETH',
  'USDT',
  'SOL',
  'BNB',
  'XRP',
  'DOT',
  'NEAR',
  'ATOM',
  'USDC',
  'BUSD',
  'TRX',
  'ADA',
  'DOGE',
  'LTC',
  'XMR',
  'LINK',
  'AVAX',
  'MATIC',
  'DAI',
]

export const SupportedCurrencies = [
  'UAH',
  'USD',
  'EUR',
  'GBP',
  'ALL',
  'AMD',
  'AZN',
  'BGN',
  'HRK',
  'CZK',
  'DKK',
  'GEL',
  'HUF',
  'ISK',
  'MDL',
  'MKD',
  'NOK',
  'PLN',
  'RON',
  'RSD',
  'SEK',
  'CHF',
  'TRY',
  'CAD',
  'AUD',
  'NZD',
  'CNY',
  'HKD',
  'TWD',
  'KRW',
  'SGD',
  'MXN',
  'KZT',
  'INR',
  'ZAR',
  'ILS',
  'AED',
  'EGP'
]

