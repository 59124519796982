import React, { PropsWithChildren } from 'react'
import { FormControl, InputLabel, Select, SelectProps, FormHelperText } from '@mui/material'
import { useField } from 'formik'

import { localizeError } from 'Shared/utils'

const menuProps = {
  PaperProps: {
    style: {
      width: 250
    },
  },
}

interface Props extends Omit<SelectProps, 'label' | 'name'> {
  name: string
  label: string
}

export const FormikSelect: React.FC<PropsWithChildren<Props>> = props => {
  const { name, label, children, ...selectProps } = props

  const [ field, meta ] = useField(name)
  const error = meta.touched && meta.error

  return (
    <FormControl error={!!error} size="small" fullWidth>
      <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
      <Select
        MenuProps={menuProps}
        {...selectProps}
        labelId={`select-label-${label}`}
        label={label}
        {...field}
        error={!!error}
      >
        {children}
      </Select>
      {!!error && <FormHelperText>{localizeError(error)}</FormHelperText>}
    </FormControl>
  )
}
