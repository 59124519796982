import { Reducer } from 'react'
import { combineReducers, AnyAction } from 'redux'
import { RouterState } from 'redux-first-history'

import { analytics } from 'Analytics/analytics-reducer'
import { holdings } from 'Holdings/holdings-reducer'
import { transactions } from 'Transactions/transactions-reducer'
import { prediction } from 'Prediction/prediction-reducer'
import { advisorPortal } from 'AdvisorPortal/advisorPortal-reducer'
import { snackbarsReducer } from 'Snackbar/snackbar-reducer'
import { demo } from 'Demo/demo-reducer'
import { sharing } from 'Sharing/sharing-reducer'
import { tasks } from 'App/Tasks/Tasks-reducer'
import { assetAllocation } from 'App/AssetAllocation/assetAllocation-reducer'
import { projectionPlan } from 'ProjectionPlan/projectionPlan-reducer'

export const rootReducer = (routerReducer: Reducer<RouterState, AnyAction>) => combineReducers({
  analytics,
  holdings,
  router: routerReducer,
  snackbars: snackbarsReducer,
  transactions,
  prediction,
  advisorPortal,
  demo,
  sharing,
  tasks,
  assetAllocation,
  projectionPlan,
})
