import { Stack } from '@mui/material'
import React from 'react'

import { ToggleHiddenMode, UserMenu } from 'App/NavigationBar/components'
import { ClientHistoryButton } from './ClientHistoryButton'

export const AppBarMobileToolbar = () => (
  <Stack
    direction="row"
    alignItems="center"
    columnGap={1}
    sx={theme => ({ color: theme.palette.common.white })}
  >
    <ToggleHiddenMode />
    <ClientHistoryButton />
    <UserMenu />
  </Stack>
)
