import { styled } from '@mui/material/styles'

import { EmptyContentPaper } from 'Shared/components/Papers'

export const CenteredOverlay = styled(EmptyContentPaper)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))
