import React from 'react'
import { useField } from 'formik'
import { TextFieldProps } from '@mui/material'

import { CustomTextField2 } from 'Shared/components/NonForm'
import { localizeError } from 'Shared/utils'

interface FormikTextFieldProps {
  name: string
  label: string
}

export const FormikTextField: React.FC<FormikTextFieldProps & TextFieldProps> = props => {
  const { name, label, onChange, ...otherProps } = props
  const [ field, meta ] = useField(name)
  const error = meta.touched && meta.error

  return (
    <CustomTextField2
      label={label}
      error={!!error}
      helperText={localizeError(error)}
      title={field?.value}

      {...field}
      {...otherProps}
    />
  )
}
