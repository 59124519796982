import React from 'react'
import { Stack } from '@mui/material'

interface Props {
  label: JSX.Element | string
  value: JSX.Element | string
}

export const TooltipLabelValue: React.FC<Props> = ({ label, value }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
    {label}
    {value}
  </Stack>
)
