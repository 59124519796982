/* eslint-disable max-len */
export const FaqStructuredData = {
  ['@context']: 'https://schema.org',
  ['@type']: 'FAQPage',
  mainEntity: [
    {
      ['@type']: 'Question',
      name: 'What is FIREkit?',
      acceptedAnswer: {
        ['@type']: 'Answer',
        text: 'The innovative FIREkit platform provides investors with a wide range of tools for asset tracking and analysis. Manage your risks, assess liquidity, group investments by asset class, set goals, view actual returns, visualize your portfolio, and much more.'
      }
    },
    {
      ['@type']: 'Question',
      name: 'How is my data secured in FIREkit?',
      acceptedAnswer: {
        ['@type']: 'Answer',
        text: 'We do not gather any personal information from users. Credentials are managed by a separate secure authentication server. All user data is anonymized, encrypted and stored in the cloud. Servers are protected by firewalls and use secure HTTPS connections. Our team uses best practices to ensure application security and modern methods of vulnerability testing.'
      }
    },
    {
      ['@type']: 'Question',
      name: 'Is FIREkit suitable for me?',
      acceptedAnswer: {
        ['@type']: 'Answer',
        text: 'Yes, if you are an individual investor, financial advisor, or educator. FIREkit is a comprehensive tool for managing and analyzing your investments. It is suitable for both beginners and experienced investors. FIREkit is also a great tool for financial advisors and educators who want to provide their clients with a convenient platform for managing their portfolios and tracking their progress.'
      }
    },
    {
      [ '@type' ]: 'Question',
      name: 'Is FIREkit free?',
      acceptedAnswer: {
        ['@type']: 'Answer',
        text: 'FIREkit platform has a free plan with limited functionality. You can also subscribe to a paid plan with more features.'
      }
    }
  ]
}

export const OrganizationStructuredData = {
  ['@context']: 'https://schema.org',
  ['@type']: 'Organization',
  name: 'FIREkit',
  url: 'https://firekit.space',
  logo: 'https://firekit.space/assets/full-logo.png',
  description: 'FIREkit is an investment tracking platform designed for individuals and financial advisors, offering portfolio tracking, analytics, and net worth forecasting.',
  email: 'support@firekit.space'
}
