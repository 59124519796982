import React from 'react'

import { ViewModeProvider, ViewMode } from 'App/ViewMode'
import { MinimalisticNavigationBar } from 'App/NavigationBar'
import { StaticPageWrapper } from 'App/Router/ContentWrappers'
import { PaymentSuccessful } from 'App/StaticPages'
import { AppFooter } from 'App/Footer/AppFooter'

export const PaymentSuccessRoute: React.FC = () => (
  <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
    <MinimalisticNavigationBar />
    <StaticPageWrapper>
      <PaymentSuccessful />
      <AppFooter />
    </StaticPageWrapper>
  </ViewModeProvider>
)
