import { remove, set, sortBy } from 'lodash'

import { Holding, Summary } from 'Services/holdings'
import type { ITypedAction } from 'Shared/types'
import { HoldingsActionTypes } from './holdings-constants'
import { HoldingsFilterState, HoldingsState, PinPayload, HoldingDrawer } from './holdings-types'

export const updateHoldingsWithPinned = (initialHoldings: Holding[], data: PinPayload): Holding[] => {
  const holdings = [ ...initialHoldings ]
  const pinnedHolding = holdings.find((holding: Holding) => holding.key === data.key)
  if (!pinnedHolding) { return holdings }

  const pinDateTime = !pinnedHolding.pinDateTime ? data.pinDate : null
  set(pinnedHolding, 'pinDateTime', pinDateTime)
  remove(holdings, (holding: Holding) => holding.key === data.key)

  const updatedHoldings = [ ...holdings, pinnedHolding ]

  return sortBy(
    updatedHoldings,
    [ 'pinDateTime', 'category', 'createdOn', 'asset.name' ]
  )
}

const openDrawerReducer = <T>(state: HoldingsState, drawer: HoldingDrawer, payload?: T): HoldingsState => ({
  ...state,
  drawers: {
    ...state.drawers,
    [drawer]: {
      isOpen: true,
      value: payload
    }
  }
})

const closeDrawerReducer = (state: HoldingsState, drawer: HoldingDrawer): HoldingsState => ({
  ...state,
  drawers: {
    ...state.drawers,
    [drawer]: {
      isOpen: false,
      value: null
    }
  }
})

export const initialState: HoldingsState = {
  isLoading: true,
  isSummaryLoading: true,
  isInitialLoadingCompleted: false,
  holdings: [],
  selectedHoldings: [],
  summary: null,
  holdingsFilter: {
    selectedTags: [],
    selectedCategories: [],
    selectedClasses: [],
    selectedCountries: [],
    searchQuery: ''
  },
  drawers: {
    [HoldingDrawer.createHolding]: { isOpen: false, value: null },
    [HoldingDrawer.viewHolding]: { isOpen: false, value: null },
    [HoldingDrawer.editHolding]: { isOpen: false, value: null },
    [HoldingDrawer.deleteHolding]: { isOpen: false, value: null },
    [HoldingDrawer.archiveHolding]: { isOpen: false, value: null },
    [HoldingDrawer.depositWithdraw]: { isOpen: false, value: null },
    [HoldingDrawer.fixProfitLoss]: { isOpen: false, value: null },
    [HoldingDrawer.changeTicker]: { isOpen: false, value: null },
    [HoldingDrawer.transfer]: { isOpen: false, value: null },
    [HoldingDrawer.buySell]: { isOpen: false, value: null },
    [HoldingDrawer.importHoldings]: { isOpen: false, value: null },
    [HoldingDrawer.connectAccount]: { isOpen: false, value: null },
    [HoldingDrawer.attachAccount]: { isOpen: false, value: null },
    [HoldingDrawer.disconnectAccount]: { isOpen: false, value: null },
    [HoldingDrawer.shareToChatbot]: { isOpen: false, value: null },
  },
  globalBackdropEnabled: false
}

export const holdings = (state: HoldingsState = initialState, action: ITypedAction<any>): HoldingsState => {
  switch(action.type) {

    case HoldingsActionTypes.SET_HOLDINGS_PAGE_LOADING: {
      return {
        ...state,
        isLoading: true,
        isSummaryLoading: true,
      }
    }

    case HoldingsActionTypes.GET_HOLDINGS_SUCCESS: {
      return {
        ...state,
        holdings: action.payload as Holding[],
        isLoading: false,
        isInitialLoadingCompleted: true,
      }
    }

    case HoldingsActionTypes.GET_HOLDINGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isInitialLoadingCompleted: true,
      }
    }

    case HoldingsActionTypes.GET_SUMMARY_SUCCESS: {
      return {
        ...state,
        summary: action.payload as Summary,
        isSummaryLoading: false,
      }
    }

    case HoldingsActionTypes.GET_SUMMARY_FAILURE: {
      return {
        ...state,
        isSummaryLoading: false,
      }
    }

    case HoldingsActionTypes.PIN_HOLDING_SUCCESS: {
      return {
        ...state,
        holdings: updateHoldingsWithPinned(state.holdings, action.payload as PinPayload)
      }
    }

    case HoldingsActionTypes.SET_SELECTED_HOLDINGS: {
      return {
        ...state,
        selectedHoldings: action.payload as string[]
      }
    }

    case HoldingsActionTypes.RESET_STATE_HOLDINGS: {
      return initialState
    }

    case HoldingsActionTypes.SET_HOLDINGS_FILTER_CHANGED: {
      const holdingsFilter = action.payload as HoldingsFilterState

      return {
        ...state,
        holdingsFilter
      }
    }

    case HoldingsActionTypes.drawers.OPEN_CREATE_HOLDING_DRAWER: return openDrawerReducer(state, HoldingDrawer.createHolding, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_CREATE_HOLDING_DRAWER: return closeDrawerReducer(state, HoldingDrawer.createHolding)
    case HoldingsActionTypes.drawers.OPEN_VIEW_HOLDING_DRAWER: return openDrawerReducer(state, HoldingDrawer.viewHolding, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_VIEW_HOLDING_DRAWER: return closeDrawerReducer(state, HoldingDrawer.viewHolding)
    case HoldingsActionTypes.drawers.OPEN_EDIT_HOLDING_DRAWER: return openDrawerReducer(state, HoldingDrawer.editHolding, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_EDIT_HOLDING_DRAWER: return closeDrawerReducer(state, HoldingDrawer.editHolding)
    case HoldingsActionTypes.drawers.OPEN_DELETE_HOLDING_MODAL: return openDrawerReducer(state, HoldingDrawer.deleteHolding, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_DELETE_HOLDING_MODAL: return closeDrawerReducer(state, HoldingDrawer.deleteHolding)
    case HoldingsActionTypes.drawers.OPEN_ARCHIVE_HOLDING_MODAL: return openDrawerReducer(state, HoldingDrawer.archiveHolding, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_ARCHIVE_HOLDING_MODAL: return closeDrawerReducer(state, HoldingDrawer.archiveHolding)
    case HoldingsActionTypes.drawers.OPEN_DEPOSIT_WITHDRAW_DRAWER: return openDrawerReducer(state, HoldingDrawer.depositWithdraw, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_DEPOSIT_WITHDRAW_DRAWER: return closeDrawerReducer(state, HoldingDrawer.depositWithdraw)
    case HoldingsActionTypes.drawers.OPEN_FIX_PROFIT_LOSS_DRAWER: return openDrawerReducer(state, HoldingDrawer.fixProfitLoss, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_FIX_PROFIT_LOSS_DRAWER: return closeDrawerReducer(state, HoldingDrawer.fixProfitLoss)
    case HoldingsActionTypes.drawers.OPEN_CHANGE_TICKER_DRAWER: return openDrawerReducer(state, HoldingDrawer.changeTicker, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_CHANGE_TICKER_DRAWER: return closeDrawerReducer(state, HoldingDrawer.changeTicker)
    case HoldingsActionTypes.drawers.OPEN_TRANSFER_DRAWER: return openDrawerReducer(state, HoldingDrawer.transfer, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_TRANSFER_DRAWER: return closeDrawerReducer(state, HoldingDrawer.transfer)
    case HoldingsActionTypes.drawers.OPEN_BUY_SELL_DRAWER: return openDrawerReducer(state, HoldingDrawer.buySell, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_BUY_SELL_DRAWER: return closeDrawerReducer(state, HoldingDrawer.buySell)
    case HoldingsActionTypes.drawers.OPEN_IMPORT_HOLDINGS_MODAL: return openDrawerReducer(state, HoldingDrawer.importHoldings)
    case HoldingsActionTypes.drawers.CLOSE_IMPORT_HOLDINGS_MODAL: return closeDrawerReducer(state, HoldingDrawer.importHoldings)
    case HoldingsActionTypes.drawers.OPEN_CONNECT_ACCOUNT_MODAL: return openDrawerReducer(state, HoldingDrawer.connectAccount)
    case HoldingsActionTypes.drawers.CLOSE_CONNECT_ACCOUNT_MODAL: return closeDrawerReducer(state, HoldingDrawer.connectAccount)
    case HoldingsActionTypes.drawers.OPEN_ATTACH_ACCOUNT_MODAL: return openDrawerReducer(state, HoldingDrawer.attachAccount, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_ATTACH_ACCOUNT_MODAL: return closeDrawerReducer(state, HoldingDrawer.attachAccount)
    case HoldingsActionTypes.drawers.OPEN_DISCONNECT_ACCOUNT_MODAL: return openDrawerReducer(state, HoldingDrawer.disconnectAccount, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_DISCONNECT_ACCOUNT_MODAL: return closeDrawerReducer(state, HoldingDrawer.disconnectAccount)
    case HoldingsActionTypes.drawers.OPEN_SHARE_TO_CHATBOT_MODAL: return openDrawerReducer(state, HoldingDrawer.shareToChatbot, action.payload)
    case HoldingsActionTypes.drawers.CLOSE_SHARE_TO_CHATBOT_MODAL: return closeDrawerReducer(state, HoldingDrawer.shareToChatbot)

    case HoldingsActionTypes.SET_BACKDROP: {
      return {
        ...state,
        globalBackdropEnabled: action.payload as boolean
      }
    }

    default:
      return state
  }
}

