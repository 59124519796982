import React from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import { useSelector } from 'react-redux'

import { Nullable } from 'Shared/types'
import { RouteBuilder, Routes } from 'Shared/constants'
import { SharingSelectors } from 'Sharing'
import { AppBarDesktop } from 'App/NavigationBar/components-app-bar/AppBarDesktop'
import { AppBarMobile } from 'App/NavigationBar/components-app-bar/AppBarMobile'
import { AppBarDesktopPanel } from './AppBarDesktopPanel'
import { AppBarMobileToolbar } from './AppBarMobileToolbar'
import { AppBarBreadcrumbs } from './AppBarBreadcrumbs'

const buildAppBarLinks = (sharingKey: Nullable<string>) => [
  {
    href: Routes.HOLDINGS,
    key: 'app.navbar.routes.backToApp',
    icon: <BusinessCenterIcon />
  },
  {
    href: Routes.ADVISOR_PORTAL,
    key: 'app.navbar.routes.advisorPortal',
    icon: <GroupOutlinedIcon />
  },
  ...(sharingKey ? [
    {
      href: RouteBuilder.buildAdvisorPortalSharingHoldings(sharingKey),
      key: 'app.navbar.routes.holdings',
      userGuideId: 'stepAssets',
      icon: <AccountBalanceOutlinedIcon />
    },
    {
      href: RouteBuilder.buildAdvisorPortalSharingTransactions(sharingKey),
      key: 'app.navbar.routes.transactions',
      userGuideId: 'stepTransactions',
      icon: <ReceiptOutlinedIcon />
    },
    {
      href: RouteBuilder.buildAdvisorPortalSharingAnalytics(sharingKey),
      key: 'app.navbar.routes.analytics',
      userGuideId: 'stepAnalytics',
      icon: <DataSaverOffOutlinedIcon />
    },
    {
      href: RouteBuilder.buildAdvisorPortalSharingForecasting(sharingKey),
      key: 'app.navbar.routes.prediction',
      userGuideId: 'stepPrediction',
      icon: <AutoGraphOutlinedIcon />
    },
  ]:[])
]

export const AppBar: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const sharingKey = useSelector(SharingSelectors.selectedSharingKey)
  const appBarLinks = React.useMemo(() => buildAppBarLinks(sharingKey), [ sharingKey ])

  if (isMobile) {
    return (
      <AppBarMobile links={appBarLinks} toolbar={<AppBarMobileToolbar />}>
        <AppBarBreadcrumbs />
      </AppBarMobile>
    )
  }

  return (
    <AppBarDesktop links={appBarLinks}>
      <AppBarDesktopPanel />
    </AppBarDesktop>
  )
}
