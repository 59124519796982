import { Stack } from '@mui/material'
import React from 'react'

import { useCurrentRoute } from 'Shared/hooks'
import { UserGuideButton } from 'App/UserGuide/UserGuideButton'
import { ToggleHiddenMode } from 'App/NavigationBar/components'
import { CurrentRoute } from 'App/NavigationBar/components-app-bar/CurrentRoute'
import { LoginButton } from 'App/NavigationBar/components-landing-shared'

export const AppBarDesktopPanel: React.FC = () => {
  const { currentRoute } = useCurrentRoute()

  return (
    <Stack direction="row" justifyContent={currentRoute ? 'space-between' : 'end'} alignItems="center" width="100%">
      {currentRoute && <CurrentRoute routeKey={currentRoute} />}

      <Stack flexDirection="row" alignItems="center" columnGap={1}>
        <ToggleHiddenMode />
        <UserGuideButton />
        <LoginButton />
      </Stack>
    </Stack>
  )
}
