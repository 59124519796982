import React from 'react'
import { Grid, IconButton, Stack } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { PortfolioAllocationType } from 'Services/portfolio'
import { NumberField, StyledForm, SubmitButton } from 'Shared/components'
import { defaultLabelRender, labelRenderProvider } from 'Shared/providers'
import { Dictionary } from 'Shared/types'
import { AssetAllocationKeySelectorProps } from 'App/AssetAllocation/assetAllocation-types'
import { AssetAllocationHighlights } from './AssetAllocationHighlights'

interface AssetAllocationFormProps {
  formProps: FormikProps<Dictionary<number>>
  allocationType: PortfolioAllocationType
  manageSettings: { canManage: boolean, NewKeyComponent?: React.FC<AssetAllocationKeySelectorProps> }
}

export const AssetAllocationForm: React.FC<AssetAllocationFormProps> = ({
  formProps,
  allocationType,
  manageSettings,
}) => {
  const { t } = useTranslation()
  const renderLabel = labelRenderProvider.get(allocationType) ?? defaultLabelRender

  const keys = React.useMemo(
    () => Object
      .keys(formProps.values)
      .sort(),
    [ formProps.values ])

  const handleAddNewKey = (key: string) => formProps.setFieldValue(`['${key}']`, 0)
  const handleRemoveKey = (prop: string) => formProps.setFieldValue(`['${prop}']`, undefined)

  return (
    <StyledForm autoComplete="off" name="asset-allocation" id="asset-allocation">
      <AssetAllocationHighlights values={formProps.values} type={allocationType} />

      {manageSettings.canManage && manageSettings.NewKeyComponent && (
        <manageSettings.NewKeyComponent onKeyAdded={handleAddNewKey} existingKeys={keys} />
      )}

      <Grid container spacing={2}>
        {keys.map((property: string) => (
          <Grid item xs={6} key={property}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <NumberField
                name={`['${property}']`}
                label={renderLabel(property)}
                prefix="%"
                placeholder={t('common.placeholders.numberValue', { value: 15 })}
                decimalScale={0}
                allowNegative={false}
                maxLength={3}
              />

              {manageSettings.canManage && (
                <IconButton color="error" size="small" onClick={() => handleRemoveKey(property)}>
                  <DeleteOutlinedIcon fontSize="inherit" />
                </IconButton>
              )}
            </Stack>
          </Grid>
        ))}
      </Grid>

      <SubmitButton formProps={formProps} />
    </StyledForm>
  )
}
