import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'

import { Routes } from 'Shared/constants'
import { AppLogoIcon } from 'Shared/assets'

const AppLogoStyled = styled(AppLogoIcon)({
  width: 24,
  height: 24,
  cursor: 'pointer',
})

interface Props {
  linkTo?: string
}

export const AppShortLogoLink = ({ linkTo = Routes.HOME }: Props) => (
  <Link
    underline="none"
    color="inherit"
    component={RouterLink}
    to={linkTo}
    display="flex"
  >
    <AppLogoStyled />
  </Link>
)
