import { format, parse } from 'date-fns'

import { Routes } from 'Shared/constants'
import type { AnalyticsState } from 'Analytics/analytics-types'
import { ChartTimePeriod } from 'Analytics/analytics-types'

export const AnalyticsActionTypes = {
  GET_CHARTS: 'GET_CHARTS',
  GET_CHARTS_SUCCESS: 'GET_CHARTS_SUCCESS',
  GET_CHARTS_FAILURE: 'GET_CHARTS_FAILURE',
  GET_CHART: 'GET_CHART',
  REFRESH_ALLOCATION_CHARTS: 'REFRESH_ALLOCATION_CHARTS',
  REFRESH_SUNBURST_ALLOCATION_CHARTS: 'REFRESH_SUNBURST_ALLOCATION_CHARTS',
  GET_CHART_SUCCESS: 'GET_CHART_SUCCESS',
  GET_CHART_FAILURE: 'GET_CHART_FAILURE',
  RESET_STATE_ANALYTICS: 'RESET_STATE_ANALYTICS',
  SET_CHART_FILTER_CHANGED: 'SET_CHART_FILTER_CHANGED',
  SET_CHARTS_TAGS: 'SET_CHARTS_TAGS',
  SET_CHART_CHANGED: 'SET_CHART_CHANGED',
}

export const InitialState: AnalyticsState = {
  isLoading: true,
  initialLoadCompleted: false,
  chartRenders: {},
  chartPreRenders: [],
  chartFilter: {
    selectedTags: [],
  },
  tags: [],
}

export const DefaultChartHeight = 500

export const DefaultChartElementsPerScreen = {
  mobile: 6,
  tablet: 12,
  desktop: 26
}

export const TimePeriodGrouping = {
  [ChartTimePeriod.ThreeMonths]: { group: (label: string) => label, takeLast: 3 },
  [ChartTimePeriod.OneYear]: { group: (label: string) => label, takeLast: 13 },
  [ChartTimePeriod.TwoYears]: { group: (label: string) => label, takeLast: 25 },
  [ChartTimePeriod.ThreeYears]: { group: (label: string) => format(parse(label, 'M-yyyy', new Date()), 'qqq-yyyy'), takeLast: 13 },
  [ChartTimePeriod.FourYears]: { group: (label: string) => format(parse(label, 'M-yyyy', new Date()), 'qqq-yyyy'), takeLast: 17 },
  [ChartTimePeriod.FiveYears]: { group: (label: string) => format(parse(label, 'M-yyyy', new Date()), 'qqq-yyyy'), takeLast: 21 },
  [ChartTimePeriod.TenYears]: { group: (label: string) => format(parse(label, 'M-yyyy', new Date()), 'yyyy'), takeLast: 11 },
  [ChartTimePeriod.ThirtyYears]: { group: (label: string) => format(parse(label, 'M-yyyy', new Date()), 'yyyy'), takeLast: 31 },
}

export const AnalyticsFavoritesTab = {
  labelKey: 'app.navbar.routes.analytics-favorites',
  value: Routes.ANALYTICS_FAVORITES
}

export const AnalyticsTabs = [
  { labelKey: 'app.navbar.routes.analytics-dynamics', value: Routes.ANALYTICS_DYNAMICS },
  { labelKey: 'app.navbar.routes.analytics-market', value: Routes.ANALYTICS_MARKET },
  { labelKey: 'app.navbar.routes.analytics-allocation', value: Routes.ANALYTICS_ALLOCATION },
]

