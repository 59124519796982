import React from 'react'
import { TypographyProps } from '@mui/material'

import { TextPrimary } from 'Shared/components/Typography'
import { Percent } from 'Shared/components/Percent'
import { TooltipLabelValue } from './TooltipLabelValue'
import { TooltipLabel } from './TooltipLabel'

interface Props {
  label: string
  color?: string
  value: number
  isHighlighted?: boolean
  hideSign?: boolean
}

export const TooltipLabelPercent: React.FC<Props & TypographyProps> = props => {
  const {
    label,
    color,
    value,
    isHighlighted,
    hideSign,
    ...typographyProps
  } = props

  const labelElement = React.useMemo(
    () => <TooltipLabel text={label} color={color} {...typographyProps} />,
    [ label, color ])

  const percentElement = React.useMemo(
    () => (
      <TextPrimary {...typographyProps}>
        <Percent value={value} isHighlighted={!!isHighlighted} hideSign={!!hideSign} />
      </TextPrimary>),
    [ value, isHighlighted ])

  return <TooltipLabelValue label={labelElement} value={percentElement} />
}
