import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { match } from 'ts-pattern'

import { TextLarge, fontRubik, TextPrimary, TextSmaller2 } from 'Shared/components/Typography'
import { RenewalPeriod } from 'Services/profile'
import { PricingPlan } from 'Pricing/pricing-types'

const OriginalPrice = styled(TextPrimary)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontFamily: fontRubik,
  fontWeight: 700,
  textDecoration: 'line-through',
  textDecorationColor: 'red',
  textDecorationThickness: 2,
})) as typeof Typography

const DiscountedPrice = styled(Typography)(() => ({
  fontFamily: fontRubik,
  fontSize: 40,
  fontWeight: 700,
  lineHeight: 'normal',
})) as typeof Typography

interface Props {
  pricingPlan: PricingPlan
}

export const PricingCardSectionPrice: React.FC<Props> = ({ pricingPlan }) => {
  const { t } = useTranslation()

  const perPeriodLabelKey = React.useMemo(
    () => match(pricingPlan)
      .with({ renewalPeriod: RenewalPeriod.Month, firstPeriodOnly: false }, () => 'pricing.perMonth')
      .with({ renewalPeriod: RenewalPeriod.Year, firstPeriodOnly: false }, () => 'pricing.perYear')
      .with({ renewalPeriod: RenewalPeriod.Month, firstPeriodOnly: true }, () => 'pricing.perFirstMonth')
      .with({ renewalPeriod: RenewalPeriod.Year, firstPeriodOnly: true }, () => 'pricing.perFirstYear')
      .otherwise(() => ''),
    [ pricingPlan ])

  const perNextPeriodLabelKey = React.useMemo(
    () => match(pricingPlan)
      .with(
        { renewalPeriod: RenewalPeriod.Month, firstPeriodOnly: true, autoRenew: true },
        () => 'pricing.promoCode.discountHints.thenPerMonth')
      .with(
        { renewalPeriod: RenewalPeriod.Year, firstPeriodOnly: true, autoRenew: true },
        () => 'pricing.promoCode.discountHints.thenPerYear')
      .with(
        { firstPeriodOnly: true, autoRenew: false },
        () => 'pricing.promoCode.discountHints.noAutoRenewal')
      .otherwise(() => null),
    [ pricingPlan ])

  const showOriginalPrice = React.useMemo(
    () => pricingPlan.firstPeriodPrice !== pricingPlan.originalPrice,
    [ pricingPlan ])

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="baseline">
        {showOriginalPrice && <OriginalPrice>${pricingPlan.originalPrice}</OriginalPrice>}
        <DiscountedPrice>${pricingPlan.firstPeriodPrice}</DiscountedPrice>
        <TextLarge noWrap>{t(perPeriodLabelKey)}</TextLarge>
      </Stack>
      <TextSmaller2>
        {perNextPeriodLabelKey ? (
          <Trans i18nKey={perNextPeriodLabelKey} values={{ price: pricingPlan.nextPeriodPrice }} />
        ) : (
          <>&nbsp;</>
        )}
      </TextSmaller2>
    </Stack>
  )
}
