import { Stack } from '@mui/material'
import React from 'react'

import { ToggleHiddenMode } from 'App/NavigationBar/components'
import { LoginButton } from 'App/NavigationBar/components-landing-shared'

export const AppBarMobileToolbar = () => (
  <Stack direction="row" alignItems="center" columnGap={1}>
    <ToggleHiddenMode />
    <LoginButton size="small" />
  </Stack>
)
