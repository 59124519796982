import React, { PropsWithChildren } from 'react'

import { InternalLink, InternalLinkProps } from './InternalLink'

interface InTextExternalLinkProps extends InternalLinkProps {
  spaceBefore?: boolean
  spaceAfter?: boolean
}

export const InTextInternalLink: React.FC<PropsWithChildren<InTextExternalLinkProps>> = props => {
  const { spaceBefore, spaceAfter, to, children, ...linkProps } = props

  return (
    <>
      {spaceBefore && ' '}
      <InternalLink {...linkProps} to={to}>{children}</InternalLink>
      {spaceAfter && ' '}
    </>
  )
}
