import React from 'react'
import { useField } from 'formik'

import { localizeError } from 'Shared/utils'
import { Dictionary } from 'Shared/types'
import { AutocompleteOption, CustomFixedTagsAutocomplete } from 'Shared/components/NonForm'

interface Props {
  name: string
  label: string
  options: AutocompleteOption[]
  autocompleteProps?: any
  inputProps?: any
  tagsProps?: any
}

export const FormikFixedTagsAutocomplete: React.FC<Props> = ({
  name,
  label,
  options,
  autocompleteProps,
  inputProps,
  tagsProps,
}) => {
  const [ field, meta, helpers ] = useField<string[]>(name)

  const error = meta.touched && meta.error
  const value = React.useMemo(() => {
    const optionsMap = options.reduce((acc, option) => ({ ...acc, [ option.value ]: option }), {} as Dictionary<AutocompleteOption>)
    return field.value.map(value => optionsMap[ value ])
  }, [ field.value ])

  const setTouched = React.useCallback(async () => {
    await helpers.setTouched(true)
  }, [ helpers ])

  const handleTagChange = React.useCallback(async (options: AutocompleteOption[]) => {
    const value = options.map(option => option.value)
    await helpers.setValue(value)
  }, [ helpers ])

  return (
    <CustomFixedTagsAutocomplete
      label={label}
      value={value}
      onChange={handleTagChange}
      options={options}
      autocompleteProps={{
        name: name,
        ...autocompleteProps,
      }}
      inputProps={{
        onBlur: setTouched,
        error: !!error,
        helperText: localizeError(error),
        ...inputProps,
      }}
      tagsProps={{
        ...tagsProps
      }}
    />
  )
}
